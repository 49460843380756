import React, { useState, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { AppDuc } from 'core-app/modules/App/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { TraceDuc } from 'core-app/modules/Trace/duc'
import { ProductsDuc } from 'core-app/modules/Products/duc'
import { merge, getIn, omit, setIn } from 'timm'
import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { Label } from 'ui-lib/components/Typography'
import { Paginator } from 'ui-lib/components/Paginator'
import { getStartIndex, getEndIndex } from 'ui-lib/components/Paginator/utils'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { SelectSearch } from 'ui-lib/components/Select'
import {
	Button,
	ButtonWithNoBorder,
	ButtonIconWrapper,
} from 'ui-lib/components/Button'
import theme from 'ui-lib/utils/base-theme'
import { Box } from 'ui-lib/utils/Box'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Icon } from 'ui-lib/icons/components/Icon'
import LeftArrowIcon from 'ui-lib/icons/left-arrow.svg'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { USER_COOKIE_KEYS } from 'ui-lib/utils/config'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { initiateSort, getTargetFilterQueries } from 'core-app/shared/helpers'
import { productTraceColumnConfig } from 'core-app/modules/Trace/components/Columns'
import { Title } from 'core-app/shared/components/Title'
import Trust from 'ui-lib/icons/trust.svg'

const PaginatorBlock = React.memo(
	({ paginationConfig, onChange, documents, startDesc, toDesc, ofDesc }) => (
		<Box padding={8}>
			<Paginator
				onClick={direction => onChange('paginate', { direction })}
				startIndex={getStartIndex(
					paginationConfig.activeIndex,
					paginationConfig.limit
				)}
				endIndex={getEndIndex(
					paginationConfig.activeIndex,
					paginationConfig.limit,
					paginationConfig.total
				)}
				totalCount={paginationConfig.total}
				startDesc={startDesc}
				toDesc={toDesc}
				ofDesc={ofDesc}
				hidePageResults={(documents || []).length === 0}
			/>
		</Box>
	)
)

const getBreadCrumbsList = t => [
	{
		label: t('breadcrumb.home'),
		name: 'home',
		isActive: true,
	},
	{
		label: t('breadcrumb.productTrace'),
		name: 'submodule-dashboard',
		isActive: false,
	},
]

const TraceListing = () => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	const location = useSelector(TraceDuc.selectors.location)
	const documents = useSelector(TraceDuc.selectors.getTraceActiveProducts)
	const isLoading = useSelector(
		TraceDuc.selectors.getProductTraceLoadingState
	)
	const activeFilters = useSelector(
		TraceDuc.selectors.getListingActiveFilters
	)
	const { isMobile } = useSelector(AppDuc.selectors.detection)

	const activeProductID = useSelector(TraceDuc.selectors.getActiveProductID)
	const allProducts = useSelector(ProductsDuc.selectors.getProducts)

	const activeProducts = allProducts.map(({ product }) => {
		return {
			id: product.id,
			label: `${product.name} (#${product.code})`,
		}
	})

	const [productID, setProductID] = useState('')

	const activeDateFilters = getIn(activeFilters, ['createdAt']) || {}

	let activeStartDate = getIn(activeDateFilters, ['gte', 0]) || null
	if (activeStartDate) activeStartDate = new Date(activeStartDate)
	let activeEndDate = getIn(activeDateFilters, ['lte', 0]) || null
	if (activeEndDate) activeEndDate = new Date(activeEndDate)

	// eslint-disable-next-line no-unused-vars
	const [startDate, setStartDate] = useState(activeStartDate)
	// eslint-disable-next-line no-unused-vars
	const [endDate, setEndDate] = useState(activeEndDate || new Date())

	const orgDetails = useSelector(AuthDuc.selectors.getAvailableOrgs)
	const currentOrgdetails = useSelector(
		AuthDuc.selectors.getCurrentOrgDetails
	)
	const activeSorts = useSelector(
		TraceDuc.selectors.getDocumentListingActiveSorts
	)

	const paginationConfig = useSelector(
		TraceDuc.selectors.getTraceListingPaginationEntries
	)

	const rootModule = 'product-trace'
	const subModule = 'delivery-order'
	const breadCrumbs = getBreadCrumbsList(t)
	/** Handlers */
	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(MainRouteDuc.types.DASHBOARD)
			)
		}
	}

	const handleChange = useCallback(
		(actionType, meta = {}) => {
			switch (actionType) {
				case 'remove_filter': {
					setStartDate()
					setEndDate(new Date())
					const { docType, type, filterType } = meta

					const currentQuery = getIn(location, ['query']) || {}

					const segments = Array.isArray(filterType)
						? filterType
						: [filterType]

					const targetFilters = omit(currentQuery, segments)
					dispatch(
						TraceDuc.creators.fetchTraceListing(
							docType,
							type,
							setIn(location, ['query'], targetFilters),
							true
						)
					)

					break
				}
				case 'initiate_sort': {
					initiateSort(
						dispatch,
						meta,
						TraceDuc.creators.fetchTraceListing,
						location,
						'sort'
					)
					break
				}
				case 'apply_search': {
					const { searchName, searchValue, docType, type } = meta

					const currentQuery = getIn(location, ['query']) || {}

					const targetQuery = merge(currentQuery, {
						q: searchName
							? `${searchName}->${searchValue}`
							: searchValue,
					})

					dispatch(
						TraceDuc.creators.fetchTraceListing(
							docType,
							[type],
							setIn(location, ['query'], targetQuery),
							true
						)
					)

					break
				}
				case 'apply_filter': {
					const {
						filterType, // the root filter key e.g status
						filterValue,
						prefix,
					} = meta
					const currentQuery = getIn(location, ['query']) || {}
					const filterQueries = omit(currentQuery, ['sort', 'q']) // exclude sort and search convention

					const targetQueries = getTargetFilterQueries(
						'',
						'date_range',
						filterValue,
						prefix
					)

					const targetFilters = merge(
						currentQuery,
						merge(filterQueries, { [filterType]: targetQueries })
					)
					dispatch(
						TraceDuc.creators.fetchTraceListing(
							'product-trace',
							'delivery-order',
							setIn(location, ['query'], targetFilters),
							true,
							productID
						)
					)
					break
				}
				case 'open_document': {
					if (meta.row) {
						const traceID =
							getIn(meta, [
								'row',
								'receiverTraceIDs',
								0,
								'traceID',
							]) || ''

						const orgID =
							getIn(meta, ['row', 'receivingPartyID']) || ''

						const traceGroupID =
							getIn(meta, [
								'row',
								'meta',
								'receiverTraceGroupID',
							]) || ''

						if (traceGroupID) {
							if (
								currentOrgdetails?.meta?.industry ===
								'petroleum'
							) {
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP_V2,
										{
											traceGroupID,
											orgID,
										}
									)
								)
							} else {
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE_GROUP,
										{
											traceGroupID,
											orgID,
										}
									)
								)
							}
						} else {
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types
										.PRODUCT_TRACE$GENERATE_PRODUCT_TRACE,
									{
										traceID,
										orgID,
									}
								)
							)
						}
					}
					break
				}
				case 'paginate': {
					const { direction } = meta || {}

					const {
						activeIndex = 0,
						currentCursor,
						nextCursor,
					} = paginationConfig

					const targetIndex = Math.max(
						direction === 'next'
							? activeIndex + 1
							: activeIndex - 1,
						0
					)

					let nextIndex = null

					if (activeIndex > targetIndex) {
						nextIndex = currentCursor
					} else if (activeIndex < targetIndex) {
						nextIndex = nextCursor
					}

					// retrigger the current route so new data is fetched again.
					dispatch(
						MainRouteDuc.creators.switchPage(
							location.type,
							location.payload,
							{
								...location.query,
								activeIndex: targetIndex,
								limit: location.query.limit || 5,
								nextIndex,
							}
						)
					)

					break
				}

				default:
					break
			}
		},
		[dispatch, location, productID, paginationConfig]
	)

	const activeLocale = useSelector(AppDuc.selectors.activeLocale)

	return (
		<Box padding={8} width="100%" height="100%">
			<Box>
				<Breadcrumb
					links={breadCrumbs}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>

			<>
				<Title title={t('productTrace.title')} icon={Trust} />
				<Box
					row
					justifyContent="space-between"
					alignItems="center"
					style={{ paddingBottom: 24 }}
				>
					<Box
						style={{
							marginTop: '16px',
							marginRight: '8px',
							width: 255,
						}}
					>
						<Box
							row
							justifyContent="flex-end"
							id="chooseTraceProduct"
						>
							<SelectSearch
								value={activeProductID}
								onChange={value => {
									if (activeProductID !== value.id) {
										setProductID(value.id)
										dispatch(
											CookieDuc.creators.setCookie({
												cookieName:
													USER_COOKIE_KEYS.TRACE_PRODUCT_ID,
												cookieValue: value.id,
											})
										)
										dispatch(
											TraceDuc.creators.fetchTraceListing(
												'product-trace',
												'delivery-order',
												{
													...location,
													activeIndex: 0,
													limit: 5,
												}
											)
										)
									}
								}}
								labelKey="label"
								valueKey="id"
								defaultLabel={t('common.selectDefaultLabel')}
								options={activeProducts}
								returnOnlyValue
								label={t('productTrace.chooseProduct')}
								required
							/>
						</Box>
					</Box>

					<Box
						row={!isMobile}
						alignItems={isMobile ? 'baseline' : 'flex-end'}
					>
						<Box
							alignItems="flex-start"
							width={125}
							margin="0px 5px"
						>
							<Label small>{t('productTrace.from')}</Label>
							<div style={{ fontSize: theme.fontSize.s }}>
								<DatePicker
									name="startDate"
									placeholder={t('productTrace.choose')}
									hideError
									value={startDate}
									maxDate={endDate}
									onChange={value => {
										setStartDate(value)
									}}
								/>
							</div>
						</Box>
						<Box
							alignItems="flex-start"
							width={125}
							margin="0px 5px"
						>
							<Label small>{t('productTrace.to')}</Label>
							<div style={{ fontSize: theme.fontSize.s }}>
								<DatePicker
									name="toDate"
									placeholder={t('productTrace.choose')}
									toDate
									hideError
									minDate={startDate}
									value={endDate}
									maxDate={new Date()}
									onChange={value => {
										setEndDate(value)
									}}
								/>
							</div>
						</Box>
						<Spacer size={10} horizontal={!isMobile} />

						<Box alignItems="flex-end" margin="0 5px" row>
							<Button
								label={t('productTrace.apply')}
								type="submit"
								extendStyles={{
									padding: '2px 20px',
								}}
								primary
								rounded
								disabled={!startDate}
								onClick={() => {
									handleChange('apply_filter', {
										type: 'product-trace',
										filterSegment: 'end_date',
										filterType: 'createdAt',
										filterValue: {
											startDate: new Date(
												startDate
											).toISOString(),
											endDate: new Date(
												endDate
											).toISOString(),
										},
									})
								}}
							/>
							<Spacer size={10} horizontal={!isMobile} />

							<ButtonWithNoBorder
								label={t('productTrace.clear')}
								style={{
									fontSize: theme.fontSize.xl,
								}}
								onClick={() => {
									handleChange('remove_filter', {
										docType: subModule,
										type: rootModule,
										filterType: ['createdAt', 'updatedAt'],
									})
								}}
							/>
						</Box>
					</Box>
				</Box>

				<Spacer size={8} />

				<Box>
					<PaginatorBlock
						paginationConfig={paginationConfig}
						onChange={handleChange}
						documents={documents}
						startDesc={t('common.showing')}
						toDesc={t('common.to')}
						ofDesc={t('common.of')}
					/>
					<BoxShadowTable
						isLoading={isLoading}
						size="large"
						columnConfig={productTraceColumnConfig({
							type: rootModule,
							docType: subModule,
							sortsMap: activeSorts,
							onChange: handleChange,
							activeLocale,
							orgDetails,
							t,
						})}
						rowData={documents}
					/>
					<PaginatorBlock
						paginationConfig={paginationConfig}
						onChange={handleChange}
						documents={documents}
						startDesc={t('common.showing')}
						toDesc={t('common.to')}
						ofDesc={t('common.of')}
					/>
				</Box>
				<Box padding="25px 0" style={{ maxWidth: 150 }}>
					<Button
						label={t('common.back')}
						rounded
						customIcon={
							<ButtonIconWrapper lightBG>
								<Icon glyph={LeftArrowIcon} />
							</ButtonIconWrapper>
						}
						onClick={() =>
							dispatch(
								MainRouteDuc.creators.switchPage(
									MainRouteDuc.types.DASHBOARD
								)
							)
						}
					/>
				</Box>
			</>
		</Box>
	)
}

export { TraceListing }

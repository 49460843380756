import React, { useState, useRef, useLayoutEffect } from 'react'
import styled from 'styled-components'
import { merge, getIn } from 'timm'
import { getDotColorStatus } from 'ui-lib/utils/helpers'
import { Label } from '../Typography'
import { Spacer } from '../../utils/Spacer'
import baseTheme from '../../utils/base-theme'
import { Box } from '../../utils/Box'

const statusColorMap = {
	bad: baseTheme.color.error,
	pending: baseTheme.color.warn,
	good: baseTheme.color.success,
}

const LabelBlock = styled(Label)(p => ({
	cursor: 'pointer',
	whiteSpace: 'nowrap',
	width: '300px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	padding: 6,
	marginLeft: 10,
	marginRight: 10,
	marginTop: 5,
	marginBottom: 5,
	color: p.theme.color.primary,
	...(p.active && {
		borderRadius: 4,
		color: p.theme.color.white,
		backgroundColor: p.theme.color.primary,
	}),
}))

const Dot = styled.div(p => ({
	width: 16,
	height: 16,
	borderRadius: '50%',
	fontSize: p.theme.fontSize.l,
	lineHeight: '16px',
	fontWeight: 'bold',
	zIndex: '2',
	backgroundColor: p.color || p.theme.color.grey8,
	textAlign: 'center',
	color: p.theme.color.white,
	...(p.hidden && { visibility: 'hidden' }),
}))

const Wrapper = styled.div(p => ({
	display: 'flex',
	alignItems: 'flex-start',
	flexDirection: 'column',
	position: 'relative',
	...(p.downLink && {
		':before': {
			content: '" "',
			width: '1px',
			height: `calc(100% - ${p.lastTrackHeight || 46}px)`,
			position: 'absolute',
			backgroundColor: p.downLinkColor,
			zIndex: '1',
			left: p.depth * 30 + 4,
			top: '22px',
		},
	}),
	...(p.leftLink && {
		':after': {
			content: '" "',
			width: '30px',
			height: '1px',
			position: 'absolute',
			backgroundColor: p.color,
			zIndex: '1',
			top: '22px',
			left: (p.depth - 1) * 30 + 4,
		},
	}),
}))

const setIn = (obj, keys, value) => {
	const lastIdx = keys.length - 1
	let o = obj
	for (let i = 0; i < keys.length; i++) {
		const k = keys[i]
		if (o[k] === undefined) {
			o[k] = {}
		}
		if (lastIdx === i) {
			o[k] = value
			break
		}
		o = o[k]
	}

	return obj
}

// The following global variables are used to maintain state because TraceTree component is
// recursively called, and hence any state maintained within the component would be lost
// activeNode is used to store which all nodes are "expanded" or not
let activeNode = {}

// selectedNode is used to store the id of the node which is currently highlighted
// let selectedNode = ''

let lastSelectedNode = ''

const isBadNode = (nodePath, badNodePaths) => {
	for (const node of Object.keys(badNodePaths)) {
		if (node.includes(nodePath)) return true
	}

	return false
}

const hasBadChildNode = (nodePath, badNodePaths) => {
	for (const node of Object.keys(badNodePaths)) {
		if (node !== nodePath && node.includes(nodePath)) return true
	}

	return false
}

const TraceSummary = ({
	track,
	onClick,
	depth = 0,
	expandDepth,
	noStatusCheck,
	parentIndex = 0,
	getLastTrackHeight,
	supplyChainSelected,
	certificatesSelected,
}) => {
	const [lastTrackHeight, setLastTrackHeight] = useState({})
	const [firstTime, setFirstTime] = useState(true)
	const [toggle, setToggle] = useState(false)

	const ref = useRef()
	// const [showToolTip, setShowTooltip] = useState(false)
	const lastTrack = useRef(null)

	useLayoutEffect(() => {
		if (lastTrack.current)
			getLastTrackHeight(lastTrack.current.getBoundingClientRect().height)
	}, [lastTrack.current]) // eslint-disable-line react-hooks/exhaustive-deps

	return track.nodes.map((obj, index) => {
		const {
			title,
			status,
			children,
			id,
			errorMsg,
			transaction,
			transforming,
			transformed,
			currentOrgDetail,
			massBalanceErrorMsg,
			certificationStatus,
			blockChainStatus,
			certType,
			supplyChainType,
			deforestationData,
			supplyBase,
			noDotColorStatus,
			virtualTraceData = null,
		} = obj

		const hasChildren = children && !!children.length
		let enabled = getIn(activeNode, [depth, index])
		if (depth <= expandDepth && enabled === undefined) {
			activeNode = setIn(activeNode, [depth, index], true)
			enabled = true
		}

		const dotColorStatus = getDotColorStatus({
			certType,
			supplyBase,
			supplyChainType,
			supplyChainSelected,
			certificatesSelected,
		})

		const setClick = () => {
			onClick({
				id,
				depth,
				index,
				transforming,
				transformed,
				transaction,
				currentOrgDetail,
				title,
				status,
				errorMsg,
				massBalanceErrorMsg,
				certificationStatus,
				blockChainStatus,
				certType,
				supplyChainType,
				deforestationData,
				noDotColorStatus,
				virtualTraceData,
			})
			lastSelectedNode = obj.path
			setFirstTime(false)
		}

		if (parentIndex === 0 && index === 0 && depth === 1 && firstTime) {
			setClick()
		}

		return (
			<Wrapper
				key={id}
				depth={depth}
				downLink={hasChildren}
				leftLink={depth > 0}
				lastTrackHeight={lastTrackHeight[id]}
				{...(index === track.length - 1 ? { ref: lastTrack } : {})}
				color={
					isBadNode(obj.path, track.badNodePaths)
						? statusColorMap.bad
						: statusColorMap.good
				}
				downLinkColor={
					hasBadChildNode(obj.path, track.badNodePaths)
						? statusColorMap.bad
						: statusColorMap.good
				}
			>
				<Box row center>
					<Spacer size={24 * depth} horizontal />
					<Dot
						color={
							noStatusCheck || noDotColorStatus
								? statusColorMap.good
								: statusColorMap[dotColorStatus]
						}
						hidden={hasChildren && !status}
						onClick={() => {
							activeNode = setIn(
								activeNode,
								[depth, index],
								!enabled
							)
							setClick()
							setToggle(!toggle)
						}}
					>
						{hasChildren && (enabled ? '-' : '+')}
					</Dot>
					<LabelBlock
						onClick={() => {
							activeNode = setIn(
								activeNode,
								[depth, index],
								!enabled
							)
							setClick()
							setToggle(!toggle)
						}}
						key={lastSelectedNode}
						active={obj.path === lastSelectedNode}
						ref={ref}
					>
						{noDotColorStatus ? <b>{title}</b> : title}
					</LabelBlock>
				</Box>
				{hasChildren && enabled && (
					<Box>
						<TraceSummary
							depth={depth + 1}
							onClick={onClick}
							parentIndex={index}
							expandDepth={expandDepth}
							noStatusCheck={noStatusCheck}
							supplyChainSelected={supplyChainSelected}
							certificatesSelected={certificatesSelected}
							track={{
								nodes: children,
								badNodePaths: track.badNodePaths,
							}}
							getLastTrackHeight={_height => {
								setLastTrackHeight(
									merge(lastTrackHeight, {
										[id]: _height,
									})
								)
							}}
						/>
					</Box>
				)}
			</Wrapper>
		)
	})
}

TraceSummary.defaultProps = {
	getLastTrackHeight: () => {},
	onClick: () => {},
}

export { TraceSummary }

import * as turf from '@turf/turf'
import React, { useEffect, useRef, useState } from 'react'
import ReactMapGL, {
	NavigationControl,
	WebMercatorViewport,
} from 'react-map-gl'
import { getIn } from 'timm'
import { getMapBoxAccessToken } from '../../config'
import { getRandomNumber } from '../../utils/helpers'

const AreaMapPreview = ({
	geoJSON = {},
	treeData = [],
	selectedDeforestOrg = {},
	height = 400,
}) => {
	const dimensions = {
		// eslint-disable-next-line no-restricted-globals
		width: screen.width,
		// eslint-disable-next-line no-restricted-globals
		height,
	}

	const _viewport = new WebMercatorViewport(dimensions)

	const selectedOrgID = getIn(selectedDeforestOrg, [0, 'value']) || ''

	const [viewport, setViewport] = useState({})

	const orgShapeMap = {}

	function extractOrgCoordinates(node) {
		if (node.id) {
			orgShapeMap[node.id] = {
				geoType: node.geoType,
				shape: node.shape,
			}
		}

		if (node.children && node.children.length > 0) {
			node.children.forEach(child => {
				extractOrgCoordinates(child)
			})
		}
	}

	treeData.forEach(item => {
		extractOrgCoordinates(item)
	})

	const mapRef = useRef(null)

	const addPolygon = (map, tree) => {
		if (tree && tree.shape && Object.keys(tree.shape).length > 0) {
			const id = `polygon-${getRandomNumber()}`
			const { geoType, shape } = tree
			const getCircleData = () => {
				const options = {
					steps: 64,
					units: 'kilometers',
				}
				let radius = 0
				shape.coordinates.forEach(item => {
					const coorDistance = turf.distance(shape.center, item)
					if (coorDistance > radius) {
						radius = coorDistance
					}
				})

				return turf.circle(shape.center, radius, options)
			}

			if (geoType === 'shapefile') {
				map.addLayer({
					id,
					type: 'fill',
					source: {
						type: 'vector',
						url: `mapbox://${shape.tilesetId}`, //  Mapbox tileset Map ID
					},
					'source-layer': shape.tilesetName, // name of tilesets
					paint: {
						'fill-color': '#90EE90',
						'fill-opacity': 0.5,
						'fill-outline-color': 'yellow',
					},
				})
			}

			if (geoType === 'coordinates') {
				map.addSource(id, {
					type: 'geojson',
					data: getCircleData(),
				})

				// Add a new layer to visualize the polygon.
				map.addLayer({
					id,
					type: 'fill',
					source: id, // reference the data source
					layout: {},
					paint: {
						'fill-color': '#90EE90', // color fill
						'fill-opacity': 0.5,
					},
				})
				// Add a outline around the polygon.
				map.addLayer({
					id: 'outline_org',
					type: 'line',
					source: id,
					layout: {},
					paint: {
						'line-width': 1,
						'line-color': 'yellow',
					},
				})
			}
		}
	}

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const checkAndUpdateZoom = (deforestJson, orgJson = {}) => {
		const { geoType, shape: { coordinates: orgCoords = [] } = {} } = orgJson

		let zoomCoords = deforestJson

		if (orgJson && geoType === 'coordinates') {
			const orgPolyGon = {
				geometry: {
					coordinates: [orgCoords],
					type: 'Polygon',
				},
				properties: {},
				type: 'Feature',
			}

			zoomCoords = {
				...deforestJson,
				features: [...deforestJson.features, orgPolyGon],
			}
		}

		const bbox = turf.bbox(zoomCoords)

		const { longitude: x, latitude: y, zoom: zm } = _viewport.fitBounds(
			[
				[bbox[0], bbox[1]],
				[bbox[2], bbox[3]],
			],
			{
				padding: 20,
			}
		)

		setViewport({
			...dimensions,
			longitude: x,
			latitude: y,
			zoom: zm,
		})
	}
	const onMapLoad = React.useCallback(() => {
		const map = mapRef.current.getMap()
		const img = new Image(15, 15)
		// img.onload = () => map.addImage('arrowIcon', img)
		img.src =
			"data:image/svg+xml,%3Csvg viewBox='0 0 1024 1024' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M529.6128 512L239.9232 222.4128 384.7168 77.5168 819.2 512 384.7168 946.4832 239.9232 801.5872z' p-id='9085' fill='%23FFFFFF'%3E%3C/path%3E%3C/svg%3E"
		map.addSource('maine', {
			type: 'geojson',
			data: geoJSON,
		})

		map.addLayer({
			id: 'geolayer1',
			type: 'fill',
			source: 'maine', // reference the data source
			layout: {},
			paint: {
				'fill-color': '#D9A679', // brown color fill
				'fill-opacity': 0.2,
			},
		})
		// Add a black outline around the polygon.
		map.addLayer({
			id: 'outline',
			type: 'line',
			source: 'maine',
			layout: {},
			paint: {
				'line-color': '#000',
				'line-width': 3,
			},
		})
		if (orgShapeMap[selectedOrgID]) {
			addPolygon(map, orgShapeMap[selectedOrgID])
		}
		checkAndUpdateZoom(geoJSON, orgShapeMap[selectedOrgID])
	}, [checkAndUpdateZoom, geoJSON, orgShapeMap, selectedOrgID])

	const myRef = useRef(null)
	const executeScroll = () => myRef.current.scrollIntoView()

	useEffect(() => {
		executeScroll()
	}, [])

	return (
		<div ref={myRef} style={{ display: 'block', height: '100%' }}>
			<ReactMapGL
				width="100%"
				height="100%"
				ref={mapRef}
				onLoad={onMapLoad}
				mapStyle="mapbox://styles/mapbox/satellite-streets-v12"
				mapboxApiAccessToken={getMapBoxAccessToken()}
				{...viewport}
				onViewportChange={setViewport}
			>
				<div
					style={{
						width: '30px',
					}}
				>
					<NavigationControl
						showCompass={false}
						position="top-left"
					/>
				</div>
			</ReactMapGL>
		</div>
	)
}

AreaMapPreview.defaultProps = {
	jeoJSON: [],
}

export { AreaMapPreview }

import React, { useState, useRef } from 'react'
import styled from 'styled-components'
import { Drop } from 'grommet'
import { Group } from '@vx/group'
import { Tree } from '@vx/hierarchy'
import { hierarchy } from 'd3-hierarchy'
import { LinkHorizontal, LinkVertical } from '@vx/shape'
import { Box } from '../../utils/Box'
import { Label } from '../Typography'
import { BoxShadowWrapper } from '../Card'
import SuccessIcon from '../../icons/success-round.svg'
import ErrorIcon from '../../icons/error-round.svg'
import FlagIcon from '../../icons/flag.svg'
import { Icon, IconWrapper } from '../../icons/components/Icon'
import baseTheme from '../../utils/base-theme'
import { getDotColorStatus, getTitle } from '../../utils/helpers'

const Wrapper = styled(Box)(p => ({
	color: p.theme.color.primary,
	cursor: 'pointer',
	// transform: 'scale(-1,1)', // TODO: pass as a props named flip

	backgroundColor: p.theme.color.white,
	...(p.isActive && {
		color: p.theme.color.white,
		backgroundColor: p.theme.color.primary,
	}),
	':hover': {
		color: p.theme.color.white,
		backgroundColor: p.theme.color.primary,
	},
}))

// eslint-disable-next-line no-unused-vars
const BoxWrapper = styled(BoxShadowWrapper)(p => {
	return {
		boxShadow: 'rgba(0, 0, 0, 0.1) 2px 2px 3px',
	}
})

const Dot = styled.div(p => ({
	width: 16,
	height: 16,
	marginTop: 2,
	marginLeft: 4,
	fontSize: '24px',
	lineHeight: '16px',
	zIndex: '2',
	cursor: 'pointer',
	textAlign: 'center',
	color: p.theme.color.primary,
	...(p.hidden && { visibility: 'hidden' }),
}))

const statusIconMap = {
	bad: FlagIcon,
	pending: ErrorIcon,
	good: SuccessIcon,
}

const lineColorMap = {
	bad: baseTheme.color.error,
	pending: baseTheme.color.warn,
	good: baseTheme.color.success,
}

const SingleFragment = ({
	title,
	id,
	onClick,
	isActive,
	orgType,
	noOrgType,
	certificateTypes,
	certType,
	dotColorStatus,
	unKnownPercentage = null,
	virtualTraceData = null,
}) => {
	const ref = useRef()
	const [showToolTip, setShowTooltip] = useState(false)

	const getStatus = certificate => {
		return certType.find(item => item.ctype === certificate.key)
			? certType.find(item => item.ctype === certificate.key).state
			: ''
	}

	return (
		<BoxWrapper
			width={200}
			style={{ border: '1px solid #ACB7CD' }}
			onMouseEnter={() => {
				setShowTooltip(true)
			}}
			onMouseLeave={() => {
				setShowTooltip(false)
			}}
		>
			<Wrapper
				onClick={onClick}
				row
				justifyContent="space-between"
				alignItems="center"
				padding={8}
				isActive={isActive}
				ref={ref}
			>
				<Label
					style={{
						color: 'inherit',
						whiteSpace: 'nowrap',
						width: '300px',
						overflow: 'hidden',
						textOverflow: 'ellipsis',
					}}
					link
				>
					{getTitle(title, unKnownPercentage, virtualTraceData)}
				</Label>
				{dotColorStatus && (
					<IconWrapper size={15}>
						<Icon glyph={statusIconMap[dotColorStatus]} />
					</IconWrapper>
				)}
			</Wrapper>
			{showToolTip && (
				<Drop
					target={ref.current}
					style={{
						background: 'none',
						boxShadow: 'none',
						border: 'none',
					}}
				>
					<Box
						style={{
							background: '#333',
							width: '99%',
							padding: 16,
							maxWidth: 300,
							marginTop: 40,
							borderRadius: 4,
						}}
					>
						<Label
							color="#fff"
							small
							key={title}
							style={{
								borderLeft: '3px solid #e07616',
								paddingLeft: 16,
								cursor: 'inherit',
							}}
						>
							{getTitle(
								title,
								unKnownPercentage,
								virtualTraceData
							)}
						</Label>
						{certificateTypes &&
							certificateTypes.map(certificate => {
								return (
									<Label
										color={
											getStatus(certificate) !==
											'Verified and Active'
												? '#e07616'
												: 'green'
										}
										small
										key={title}
										style={{
											borderLeft: '3px solid #e07616',
											paddingLeft: 16,
											cursor: 'inherit',
											textTransform: 'capitalize',
										}}
									>
										{`${certificate.label} : ${getStatus(
											certificate
										)}`}
									</Label>
								)
							})}
						{!noOrgType && orgType && (
							<Label
								color="#fff"
								small
								key={title}
								style={{
									borderLeft: '3px solid #e07616',
									paddingLeft: 16,
									cursor: 'inherit',
									textTransform: 'capitalize',
								}}
							>
								{orgType}
							</Label>
						)}
					</Box>
				</Drop>
			)}
		</BoxWrapper>
	)
}

const expandedNode = {}

let lastSelectedNode = ''

const isBadNode = (nodePath, badNodePaths) => {
	for (const node of Object.keys(badNodePaths)) {
		if (node.includes(nodePath)) return true
	}

	return false
}

const nodeHasChildren = ({ children }) => {
	return !!(children && children.length)
}

const getCountInEachlevel = (root, rowTotalCount) => {
	rowTotalCount[root.height] = rowTotalCount[root.height]
		? rowTotalCount[root.height] + root?.children?.length || 0
		: root?.children?.length || 0
	if (root?.children?.length) {
		root.children.forEach(child => {
			getCountInEachlevel(child, rowTotalCount)
		})
	}
}

const TraceGraph = ({
	orientation,
	data,
	onClick,
	noOrgType,
	noStatusCheck,
	certificatesSelected,
	supplyChainSelected,
	isFullScreen,
}) => {
	const stepPercent = 0.5
	const [toggle, setToggle] = useState(false)

	const margin = {
		top: 30,
		left: 30,
		right: 280,
		bottom: 100,
	}

	const { nodes, badNodePaths } = data

	const root = hierarchy(nodes, d => {
		return expandedNode[d.path] ? null : d.children
	})

	const rowTotalCount = []
	getCountInEachlevel(root, rowTotalCount)
	const maxCount = Math.max(
		...rowTotalCount.keys().map(o => rowTotalCount[o])
	)
	const heightNeeded = maxCount * 25 * (root.height + 1)

	/** Calculate height by the level of children and expand to that */
	const width = (isFullScreen ? 320 : 250) * (root.height + 1)
	const height = heightNeeded > 300 ? heightNeeded : 300

	const innerWidth = width - margin.left - margin.right
	const innerHeight = height - margin.top - margin.bottom

	let sizeWidth
	let sizeHeight

	const origin = { x: 0, y: 0 }
	if (orientation === 'vertical') {
		sizeWidth = innerWidth
		sizeHeight = innerHeight
	} else {
		sizeWidth = innerHeight
		sizeHeight = innerWidth
	}

	const getLineStroke = link => {
		let hasBadPath = false
		if (link.target.data) {
			const detail = link.target.data
			const dotColorStatus = getDotColorStatus({
				certType: detail.certType,
				supplyBase: detail.supplyBase,
				supplyChainType: detail.supplyChainType,
				certificatesSelected,
				supplyChainSelected,
			})
			if (dotColorStatus === 'bad') {
				hasBadPath = true
			}
		}
		if (link.target.children && link.target.children.length > 0) {
			link.target.children.forEach(child => {
				if (child.data) {
					const detail = child.data
					const dotColorStatus = getDotColorStatus({
						certType: detail.certType,
						supplyBase: detail.supplyBase,
						supplyChainType: detail.supplyChainType,
						certificatesSelected,
						supplyChainSelected,
					})
					if (dotColorStatus === 'bad') {
						hasBadPath = true
					}
				}
			})
		}

		return hasBadPath ? lineColorMap.bad : lineColorMap.good
	}

	return (
		<svg width={width} height={height}>
			<Group top={margin.top} left={margin.left}>
				<Tree
					root={root}
					size={[sizeWidth, sizeHeight]}
					separation={(a, b) =>
						(a.parent === b.parent ? 20 : 10) / a.depth
					}
				>
					{_data => (
						<Group top={origin.y} left={origin.x}>
							{_data.links().map((link, i) => {
								const LinkComponent =
									orientation === 'vertical'
										? LinkVertical
										: LinkHorizontal

								return (
									<LinkComponent
										data={link}
										percent={+stepPercent}
										/** stroke={
											isBadNode(
												link.target.data.path,
												badNodePaths
											)
												? lineColorMap.bad
												: lineColorMap.good
										} */
										stroke={
											noStatusCheck
												? lineColorMap.good
												: getLineStroke(link)
										}
										strokeWidth="2"
										fill="none"
										key={i} // eslint-disable-line react/no-array-index-key
									/>
								)
							})}

							{_data.descendants().map((node, key) => {
								let top
								let left

								if (orientation === 'vertical') {
									top = node.y
									left = node.x
								} else {
									top = node.x
									left = node.y
								}

								return (
									// eslint-disable-next-line react/no-array-index-key
									<Group top={top} left={left} key={key}>
										<foreignObject
											width={200}
											height={100}
											x={
												orientation === 'vertical'
													? -100
													: 0
											}
											y={
												orientation === 'vertical'
													? 0
													: -20
											}
											onClick={() => {
												onClick(node.data)

												setToggle(!toggle)
												lastSelectedNode =
													node.data.path
											}}
										>
											<Box row>
												<SingleFragment
													title={node.data.title}
													id={node?.data?.id}
													noOrgType={noOrgType}
													unKnownPercentage={
														node?.data
															?.unKnownPercentage
													}
													virtualTraceData={
														node?.data
															?.virtualTraceData
													}
													certificateTypes={
														node.data
															.certificateTypes
													}
													certType={
														node.data.certType
													}
													orgType={node.data.orgType}
													status={
														isBadNode(
															node.data.path,
															badNodePaths
														)
															? 'bad'
															: 'good'
													}
													onClick={() => {
														lastSelectedNode =
															node.data.path
													}}
													isActive={
														node.data.path ===
														lastSelectedNode
													}
													dotColorStatus={
														noStatusCheck
															? lineColorMap.good
															: getDotColorStatus(
																	{
																		certType:
																			node
																				.data
																				.certType,
																		supplyBase:
																			node
																				.data
																				.supplyBase,
																		supplyChainType:
																			node
																				.data
																				.supplyChainType,
																		certificatesSelected,
																		supplyChainSelected,
																	}
															  )
													}
												/>

												{nodeHasChildren(node.data) && (
													<Dot
														onClick={() => {
															expandedNode[
																node.data.path
															] = !expandedNode[
																node.data.path
															]
														}}
													>
														{expandedNode[
															node.data.path
														]
															? '+'
															: '—'}
													</Dot>
												)}
											</Box>
										</foreignObject>
									</Group>
								)
							})}
						</Group>
					)}
				</Tree>
			</Group>
		</svg>
	)
}

TraceGraph.defaultProps = {
	orientation: 'horizontal',
	data: [],
	onClick: () => {},
}

export { TraceGraph, SingleFragment }

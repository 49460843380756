export const initialState = {
	activeModule: '',
	loading: false,
	error: false,
	modules: {
		assets: {
			loading: false,
			assetsListing: {},
			certificates: [],
			tankTimeline: [],
			initialVehicleDetails: {
				name: '',
				number: '',
				make: '',
				model: '',
				capacity: '',
				capacityUOM: '',
				type: '',
			},
			vehicleDetails: {},
			initialValuesProductionLine: {
				name: '',
				capacity: '',
				capacityUOM: 'mT',
				productID: '',
				productionRate: '',
			},
			initialWeighBridgeDetails: {
				name: '',
				manufacturer: '',
				model: '',
				capacity: '',
				capacityUOM: 'MT',
				lastcalibrated: '',
				calibrationvalidity: '',
				calibrationcertificate: '',
				calibratedby: '',
				files: '',
			},
			productionLineDetails: {},
			initialValuesStorageUnit: {
				type: '',
				name: '',
				capacity: '',
				capacityUOM: '',
				initialQuantity: '',
				supplyChainModel: 'mass-balance',
				productID: '',
				reservedFor: '',
			},
			storageUnitDetails: {},
			tabsConfig: {
				palmoil_plantation: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_plantation_administrator: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_plantation_officemgmt: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_mill: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'production-line',
						title: 'assetsHome.productionLine',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_mill_administrator: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'production-line',
						title: 'assetsHome.productionLine',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_mill_officemgmt: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'production-line',
						title: 'assetsHome.productionLine',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_kernelmill: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'production-line',
						title: 'assetsHome.productionLine',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_kernelmill_administrator: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'production-line',
						title: 'assetsHome.productionLine',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_kernelmill_officemgmt: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'production-line',
						title: 'assetsHome.productionLine',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_refinery: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'production-line',
						title: 'assetsHome.productionLine',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_refinery_administrator: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'production-line',
						title: 'assetsHome.productionLine',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_refinery_officemgmt: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'production-line',
						title: 'assetsHome.productionLine',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_trader: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_trader_administrator: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_trader_officemgmt: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_ffbdealer: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_ffbdealer_administrator: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_ffbdealer_officemgmt: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_ffbsubdealer: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_ffbsubdealer_administrator: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_ffbsubdealer_officemgmt: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_collectionpoint: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_collectionpoint_administrator: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_collectionpoint_officemgmt: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_storage: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_storage_administrator: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_storage_officemgmt: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_manufacturer: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_manufacturer_administrator: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
				palmoil_manufacturer_officemgmt: [
					{
						code: 'vehicles',
						title: 'assetsHome.vehicles',
					},
					{
						code: 'storageunits',
						title: 'assetsHome.storageUnits',
					},
					{
						code: 'weighbridges',
						title: 'assetsHome.weighbridges',
					},
				],
			},
		},
		employee: {
			loading: false,
			activeSorts: {},
			activeFilters: {},
			employeeRole: {
				original: '',
				modified: '',
			},
			pagination: {},
			initialEmployeeDetails: {
				employeeName: '',
				designation: '',
				email: '',
				mobile: '',
				employeeID: '',
				dibizRole: '',
			},
			employeeDetails: {},
			employeeListing: {},
		},
		companyInformation: {},
		certificateDetails: [],
		businessDetails: {},
		childOrgs: [],
		showCpSuccessModal: false,
		carbonNumber: {},
	},
}

export const INITIAL_TYPES = [
	'vehicles',
	'storageunits',
	'production-line',
	'weighbridges',
]

export const TABLE_ALIAS = {
	assetName: 'assetsHome.assetName',
	unitNumber: 'assetsHome.unitNumber',
	currentQuantity: 'assetsHome.currentQuantity',
	product: 'assetsHome.product',
	supplyChainModel: 'assetsHome.scModel',
	certificationType: 'assetsHome.certificationType',
	assetType: 'assetsHome.assetType',
	capacity: 'assetsHome.capacity',
	assetNumber: 'assetsHome.assetsNumber',
	employeeID: 'empMgmtHome.employeeID',
	employeeName: 'empMgmtHome.empName',
	designation: 'empMgmtHome.designation',
	dibizRole: 'empMgmtHome.dibizRole',
	manufacturer: 'assetsHome.manufacturer',
	model: 'assetsHome.model',
}

export const DIbizRoleActorBased = {
	palmoil_plantation: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'checker',
			label: 'empMgmtHome.checker',
			details: 'empMgmtHome.checkerInfo',
		},
		{
			name: 'harvester',
			label: 'empMgmtHome.harvester',
			details: 'empMgmtHome.harvesterInfo',
		},
		{
			name: 'tractor-operator',
			label: 'empMgmtHome.tractor-operator',
			details: 'empMgmtHome.tractorInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
	],
	palmoil_plantation_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'checker',
			label: 'empMgmtHome.checker',
			details: 'empMgmtHome.checkerInfo',
		},
		{
			name: 'harvester',
			label: 'empMgmtHome.harvester',
			details: 'empMgmtHome.harvesterInfo',
		},
		{
			name: 'tractor-operator',
			label: 'empMgmtHome.tractor-operator',
			details: 'empMgmtHome.tractorInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
	],
	palmoil_plantation_officemgmt: [
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'checker',
			label: 'empMgmtHome.checker',
			details: 'empMgmtHome.checkerInfo',
		},
		{
			name: 'harvester',
			label: 'empMgmtHome.harvester',
			details: 'empMgmtHome.harvesterInfo',
		},
		{
			name: 'tractor-operator',
			label: 'empMgmtHome.tractor-operator',
			details: 'empMgmtHome.tractorInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
	],
	palmoil_mill: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
		{
			name: 'grader',
			label: 'empMgmtHome.grader',
			details: 'empMgmtHome.ffbGraderInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
	],
	palmoil_mill_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
		{
			name: 'grader',
			label: 'empMgmtHome.grader',
			details: 'empMgmtHome.ffbGraderInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
	],
	palmoil_mill_officemgmt: [
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
		{
			name: 'grader',
			label: 'empMgmtHome.grader',
			details: 'empMgmtHome.ffbGraderInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
	],
	palmoil_kernelmill: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_kernelmill_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_kernelmill_officemgmt: [
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_refinery: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_refinery_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_refinery_officemgmt: [
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_storage: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_storage_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_storage_officemgmt: [
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_trader: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	palmoil_trader_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	palmoil_trader_officemgmt: [
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
	],
	palmoil_manufacturer: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	palmoil_manufacturer_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	palmoil_manufacturer_officemgmt: [
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
	],
	palmoil_ffbdealer: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],

	palmoil_ffbdealer_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_ffbdealer_officemgmt: [
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_ffbsubdealer: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_ffbsubdealer_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_ffbsubdealer_officemgmt: [
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_collectionpoint: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_collectionpoint_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_collectionpoint_officemgmt: [
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
		{
			name: 'driver',
			label: 'empMgmtHome.driver',
			details: 'empMgmtHome.driverInfo',
		},
		{
			name: 'weighbridgeClerk',
			label: 'empMgmtHome.weighbridgeClerk',
			details: 'empMgmtHome.weighbridgeClerkInfo',
		},
	],
	palmoil_broker: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'office-management',
			label: 'empMgmtHome.office-management',
			details: 'empMgmtHome.officemgmtInfo',
		},
	],
	marketplace_palmoil_plantation: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
	],
	marketplace_palmoil_plantation_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
	],
	marketplace_palmoil_mill: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	marketplace_palmoil_mill_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	marketplace_palmoil_kernelmill: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	marketplace_palmoil_kernelmill_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	marketplace_palmoil_refinery: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	marketplace_palmoil_refinery_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	marketplace_palmoil_storage: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
	],
	marketplace_palmoil_storage_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
	],
	marketplace_palmoil_trader: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	marketplace_palmoil_trader_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	marketplace_palmoil_manufacturer: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	marketplace_palmoil_manufacturer_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
		{
			name: 'purchase-officer',
			label: 'empMgmtHome.purchase-officer',
			details: 'empMgmtHome.purchaseOfficerInfo',
		},
		{
			name: 'sales-marketing-officer',
			label: 'empMgmtHome.sales-marketing-officer',
			details: 'empMgmtHome.salesMarketingOfficerInfo',
		},
	],
	marketplace_palmoil_ffbdealer: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
	],

	marketplace_palmoil_ffbdealer_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
	],
	marketplace_palmoil_ffbsubdealer: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
	],
	marketplace_palmoil_ffbsubdealer_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
	],
	marketplace_palmoil_collectionpoint: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
	],
	marketplace_palmoil_collectionpoint_administrator: [
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
	],
	marketplace_palmoil_broker: [
		{
			name: 'owner',
			label: 'empMgmtHome.owner',
			details: 'empMgmtHome.ownerInfo',
		},
		{
			name: 'administrator',
			label: 'empMgmtHome.administrator',
			details: 'empMgmtHome.adminInfo',
		},
	],
}

export const ASSETS_ALIAS = {
	tank: 'createAsset.storage-tank',
	warehouse: 'createAsset.warehouse',
	land: 'createAsset.yard',
	container: 'createAsset.bin',
	area: 'createAsset.area',
}

export const INVENTORY_TYPES = {
	incoming: 'viewAsset.incoming',
	intermediate: 'viewAsset.intermediate',
	outgoing: 'viewAsset.outgoing',
}

export const getStorageTypes = (actor, t) => {
	if (actor.includes('palmoil_plantation')) {
		return [
			{
				name: 'container',
				label: t('createAsset.bin'),
			},
		]
	}
	if (
		actor.includes('palmoil_mill') ||
		actor.includes('palmoil_kernelmill')
	) {
		return [
			{
				name: 'tank',
				label: t('createAsset.storage-tank'),
			},
			{
				name: 'area',
				label: t('createAsset.area'),
			},
		]
	}

	return [
		{
			name: 'tank',
			label: t('createAsset.storage-tank'),
		},
	]
}

export const inventoryTypes = (
	storageType,
	checkIfIncomingAreaExists,
	isStorageCompany,
	t
) => {
	if (isStorageCompany) {
		return [
			{
				label: t('common.outgoing'),
				name: 'outgoing',
			},
		]
	}

	if (storageType === 'area') {
		return checkIfIncomingAreaExists
			? [
					{
						label: t('common.outgoing'),
						name: 'outgoing',
					},
			  ]
			: [
					{
						label: t('common.incoming'),
						name: 'incoming',
					},
					{
						label: t('common.outgoing'),
						name: 'outgoing',
					},
			  ]
	}

	if (storageType === 'container') {
		return [
			{
				label: t('common.incoming'),
				name: 'incoming',
			},
		]
	}

	return [
		{
			label: t('common.incoming'),
			name: 'incoming',
		},
		{
			label: t('common.outgoing'),
			name: 'outgoing',
		},
	]
}

export const getAssetUOMs = {
	vehicle: [
		{
			name: 'Tons',
			label: 'createAsset.tons',
		},
		{
			name: 'Kg',
			label: 'createAsset.kg',
		},
	],
	warehouse: [
		{
			name: 'Sq.ft',
			label: 'createAsset.sqFt',
		},
		{
			name: 'Sq. m',
			label: 'createAsset.sqM',
		},
	],
	tank: [
		{
			name: 'mT',
			label: 'createAsset.mT',
		},
	],
	container: [
		{
			name: 'mT',
			label: 'createAsset.mT',
		},
	],
	land: [
		{
			name: 'Sq.ft',
			label: 'createAsset.sqFt',
		},
		{
			name: 'Sq. m',
			label: 'createAsset.sqM',
		},
	],
	'production-line': [
		{
			name: 'mT',
			label: 'createAsset.mT',
		},
	],
}

export const getVehicleTypes = t => {
	return [
		{
			name: '2 Wheeler',
			label: t('createAsset.2wheeler'),
		},
		{
			name: '3 Wheeler',
			label: t('createAsset.3wheeler'),
		},
		{
			name: '4 Wheeler',
			label: t('createAsset.4wheeler'),
		},
		{
			name: 'Light truck',
			label: t('createAsset.lightTruck'),
		},
		{
			name: 'Heavy truck',
			label: t('createAsset.heavyTruck'),
		},
		{
			name: 'Tanker',
			label: t('createAsset.tanker'),
		},
		{
			name: 'Tractor',
			label: t('createAsset.tractor'),
		},
	]
}

export const getSupplyChainModels = t => {
	return [
		{
			name: 'identity-preserved',
			label: t('createAsset.identity-preserved'),
		},
		{
			name: 'segregated',
			label: t('createAsset.segregated'),
		},
		{
			name: 'mass-balance',
			label: t('createAsset.mass-balance'),
		},
	]
}

export const getSupplyChainModelsWithId = t => {
	return [
		{
			name: t('tdmDetailsEntry.identity-preserved'),
			id: 'identity-preserved',
		},
		{
			name: t('tdmDetailsEntry.segregated'),
			id: 'segregated',
		},
		{
			name: t('tdmDetailsEntry.mass-balance'),
			id: 'mass-balance',
		},
	]
}

export const getSupplyChainModelsForRadioButton = t => {
	return [
		{
			label: t('tdmDetailsEntry.identity-preserved'),
			name: 'identity-preserved',
		},
		{
			label: t('tdmDetailsEntry.segregated'),
			name: 'segregated',
		},
		{
			label: t('tdmDetailsEntry.mass-balance'),
			name: 'mass-balance',
		},
		{
			label: t('createAsset.none'),
			name: 'none',
		},
	]
}

export const getSupplyChainModelNone = t => {
	return [
		{
			name: t('createAsset.none'),
			id: 'none',
		},
	]
}

export const orgKeys = [
	'name',
	'type',
	'description',
	'address',
	'location',
	'mobile',
	'email',
]

export const farmDetailsKeys = [
	'area',
	'plantationArea',
	'plantationTime',
	'farmAddress',
]

export const FARMDETAILS_ALIASES_KEYS = {
	area: 'onboarding.fieldSize',
	plantationArea: 'onboarding.plantationArea',
	plantationTime: 'onboarding.plantationTime',
	farmAddress: 'onboarding.farmAddress',
}

export const ORG_ALIASES_KEYS = {
	name: 'viewCompanyInfo.name',
	type: 'viewCompanyInfo.type',
	description: 'tdmDetailsEntry.description',
	address: 'viewCompanyInfo.address',
	location: 'viewCompanyInfo.location',
	mobile: 'viewCompanyInfo.mobile',
	email: 'viewCompanyInfo.email',
	certOrg: 'viewCompanyInfo.certOrg',
	certNum: 'viewCompanyInfo.certNum',
	certName: 'viewCompanyInfo.certName',
	issueDate: 'viewCompanyInfo.issueDate',
	expiryDate: 'viewCompanyInfo.expiryDate',
	certStartDate: 'viewCompanyInfo.certStartDate',
	certBody: 'viewCompanyInfo.certBody',
	license: 'viewCompanyInfo.license',
	scmodel: 'viewCompanyInfo.scModel',
	number: 'viewCompanyInfo.docNumber',
	issuingBody: 'viewCompanyInfo.issuingBody',
	updatedAt: 'viewCompanyInfo.updatedAt',
	updatedBy: 'viewCompanyInfo.updatedBy',
	verified: 'viewCompanyInfo.verified',
	rejected: 'viewCompanyInfo.rejected',
	'in-review': 'viewCompanyInfo.inReview',
	unverified: 'viewCompanyInfo.unverified',
	verifiedBy: 'viewCompanyInfo.verifiedBy',
	verifiedOn: 'viewCompanyInfo.verifiedOn',
	verificationStatus: 'viewCompanyInfo.verificationStatus',
	createdAt: 'viewCompanyInfo.uploadedOn',
}

export const certKeys1 = [
	'certName',
	'certNum',
	'issueDate',
	'expiryDate',
	'certStartDate',
	'certBody',
]

export const certKeys2 = [
	'scmodel',
	'verificationStatus',
	'verifiedBy',
	'verifiedOn',
	'createdAt',
]
export const areaKeys = ['updatedAt']
export const docKeys = ['type', 'number', 'issueDate', 'updatedBy', 'createdAt']
export const docKeys2 = ['issuingBody', 'issuingBodyOthers']

export const ORG_STATUS_TEXT = {
	pending: 'Pending for Approval',
	verified: 'APPROVED',
	active: 'ACTIVE',
	'in-review': 'IN REVIEW',
	unverified: 'UNVERIFIED',
	rejected: 'REJECTED',
}

export const YEAR = [
	{
		label: '2010',
		name: '2010',
	},
	{
		label: '2011',
		name: '2011',
	},
	{
		label: '2012',
		name: '2012',
	},
	{
		label: '2013',
		name: '2013',
	},
	{
		label: '2014',
		name: '2014',
	},
	{
		label: '2015',
		name: '2015',
	},
	{
		label: '2016',
		name: '2016',
	},
	{
		label: '2017',
		name: '2017',
	},
	{
		label: '2018',
		name: '2018',
	},
	{
		label: '2019',
		name: '2019',
	},
	{
		label: '2020',
		name: '2020',
	},
	{
		label: '2021',
		name: '2021',
	},
	{
		label: '2022',
		name: '2022',
	},
	{
		label: '2023',
		name: '2023',
	},
	{
		label: '2024',
		name: '2024',
	},
]

import Duck from 'extensible-duck'
import { setIn, getIn } from 'timm'
import { createSelector } from 'reselect'

const initialState = {
	activeModule: '',
	loading: false,
	pagination: {
		activeIndex: 0, // total, pageCount
		limit: 0,
		total: 0,
		nextCursor: '',
	},
	hasError: false,
	isSaving: false,
	activeProducts: [],
	activeProductID: '',
	readOnlySections: [],
	activePartner: {},
	activeSorts: {},
	activeFilters: {},
	activeRecord: {
		tree: [],
		graph: {},
		map: [],
	},
	parentDocRef: {},
	participantingUsers: {},
	geoData: {},
	badNodePaths: {},
	isBlockChainDisabled: false,
	hederaMessages: [],
	deforestationEvents: [],
}

export const TraceDuc = new Duck({
	namespace: 'trace',
	store: 'global',
	types: [
		'SET_ACTIVE_MODULE',
		'FETCH_PRODUCT_TRACE_LISTING',
		'TRACE_DOCUMENT_LOADING_STATUS',
		'SET_ACTIVE_PRODUCT_ID',
		'SET_TRACE_PAGINATION_ENTRIES',
		'SET_ACTIVE_PRODUCT_LISTING',
		'FETCH_PRODUCT_ORG_TRACE',
		'FETCH_PRODUCT_TRACE',
		'FETCH_GEO_DATA',
		'FETCH_PRODUCT_TREE_TRACE_SUCCESS',
		'FETCH_PRODUCT_GRAPH_TRACE_SUCCESS',
		'SET_GEO_DATA',
		'FETCH_PRODUCT_MAP_TRACE_SUCCESS',
		'SET_ACTIVE_SORTS',
		'SET_ACTIVE_FILTERS',
		'FLUSH_TRACE_LISTING',
		'FLUSH_TRACE_DOCUMENT',
		'FETCH_BAD_NODE_PATHS',
		'CHECK_BLOCKCHAIN_DISABLED',
		'SET_BLOCKCHAIN_STATUS',
		'GET_HEDERA_MESSAGES',
		'SET_HEDERA_MESSAGES',
		'GET_DEFORESTATION_EVENTS',
		'SET_DEFORESTATION_EVENTS',
	],
	initialState,
	reducer: (state, action, duck) => {
		switch (action.type) {
			case duck.types.SET_ACTIVE_MODULE: {
				return setIn(state, ['activeModule'], action.module)
			}
			case duck.types.TRACE_DOCUMENT_LOADING_STATUS: {
				const { status } = action

				return setIn(state, ['loading'], status)
			}
			case duck.types.SET_ACTIVE_PRODUCT_ID: {
				const { activeProductID } = action

				return setIn(state, ['activeProductID'], activeProductID)
			}
			case duck.types.SET_TRACE_PAGINATION_ENTRIES: {
				const {
					activeIndex,
					limit,
					total,
					nextCursor,
					currentCursor,
				} = action
				const root = ['pagination']
				let nextState = state

				nextState = setIn(
					nextState,
					[root, 'activeIndex'],
					activeIndex || 0
				)

				if (limit) nextState = setIn(nextState, [root, 'limit'], limit)

				if (total) nextState = setIn(nextState, [root, 'total'], total)

				if (nextCursor)
					nextState = setIn(
						nextState,
						[root, 'nextCursor'],
						nextCursor
					)

				if (currentCursor)
					nextState = setIn(
						nextState,
						[root, 'currentCursor'],
						currentCursor
					)

				return nextState
			}
			case duck.types.SET_ACTIVE_PRODUCT_LISTING: {
				const { listing } = action

				return setIn(state, ['activeProducts'], listing)
			}
			case duck.types.FETCH_PRODUCT_TREE_TRACE_SUCCESS: {
				const { traceDocuments } = action

				return setIn(state, ['activeRecord', 'tree'], traceDocuments)
			}

			case duck.types.FETCH_PRODUCT_GRAPH_TRACE_SUCCESS: {
				const { traceDocuments } = action

				return setIn(state, ['activeRecord', 'graph'], traceDocuments)
			}

			case duck.types.FETCH_PRODUCT_MAP_TRACE_SUCCESS: {
				const { traceDocuments } = action

				return setIn(state, ['activeRecord', 'map'], traceDocuments)
			}

			case duck.types.SET_ACTIVE_SORTS: {
				const { sortMap } = action

				let sorts = {}
				;(sortMap.sort || []).forEach(key => {
					const arr = key.split('|')
					arr.shift() // remove the first fragment since type is already decided
					const lastVal = arr.pop()

					sorts = setIn(sorts, arr, lastVal)
				})

				return setIn(state, ['activeSorts'], sorts)
			}
			case duck.types.SET_ACTIVE_FILTERS: {
				const { filters = {} } = action

				return setIn(state, ['activeFilters'], filters)
			}

			case duck.types.FLUSH_TRACE_LISTING: {
				return setIn(
					state,
					['activeFilters'],
					initialState.activeFilters
				)
			}

			case duck.types.FLUSH_TRACE_DOCUMENT: {
				return setIn(state, ['activeRecord'], initialState.activeRecord)
			}

			case duck.types.SET_GEO_DATA: {
				const { data } = action

				return setIn(state, ['geoData'], data)
			}

			case duck.types.FETCH_BAD_NODE_PATHS: {
				return setIn(state, ['badNodePaths'], action.badNodePaths)
			}

			case duck.types.SET_BLOCKCHAIN_STATUS: {
				return setIn(state, ['isBlockChainDisabled'], action.status)
			}

			case duck.types.SET_HEDERA_MESSAGES: {
				const { data } = action

				return setIn(state, ['hederaMessages'], data)
			}
			case duck.types.SET_DEFORESTATION_EVENTS: {
				const { data } = action

				return setIn(state, ['deforestationEvents'], data)
			}

			default:
				return state
		}
	},
	selectors: {
		auth: state => state.auth,
		location: state => state.location,
		traceState: state => getIn(state, ['trace']) || {},
		activeModule: state =>
			getIn(state, ['trace', 'activeModule']) || 'Error',
		getTraceActiveProducts: new Duck.Selector(selectors =>
			createSelector(
				selectors.traceState,
				traceState => traceState.activeProducts
			)
		),
		getProductTraceLoadingState: new Duck.Selector(selectors =>
			createSelector(
				selectors.traceState,
				traceState => traceState.loading
			)
		),
		getListingActiveFilters: new Duck.Selector(selectors =>
			createSelector(
				selectors.traceState,
				traceState => traceState.activeFilters
			)
		),
		getActiveProductID: new Duck.Selector(selectors =>
			createSelector(
				selectors.traceState,
				traceState => traceState.activeProductID || ''
			)
		),
		getDocumentListingActiveSorts: new Duck.Selector(selectors =>
			createSelector(
				selectors.traceState,
				traceState => traceState.activeSorts || {}
			)
		),
		getTraceListingPaginationEntries: new Duck.Selector(selectors =>
			createSelector(
				selectors.traceState,
				traceListing => traceListing.pagination
			)
		),

		getActiveTraceTreeRecord: new Duck.Selector(selectors =>
			createSelector(
				selectors.traceState,
				traceState => traceState.activeRecord.tree
			)
		),

		getActiveTraceGraphRecord: new Duck.Selector(selectors =>
			createSelector(
				selectors.traceState,
				traceState => traceState.activeRecord.graph || {}
			)
		),
		getActiveTraceMapRecord: new Duck.Selector(selectors =>
			createSelector(
				selectors.traceState,
				traceState => traceState.activeRecord.map || []
			)
		),
		getGeoData: state => getIn(state, ['trace', 'geoData']) || {},
		getBadNodePaths: state => getIn(state, ['trace', 'badNodePaths']) || {},
		getBlockChainStatus: state =>
			getIn(state, ['trace', 'isBlockChainDisabled']) || false,
		getHederaMessages: state =>
			getIn(state, ['trace', 'hederaMessages']) || [],
		getDeforestationEvents: state =>
			getIn(state, ['trace', 'deforestationEvents']) || [],
	},
	creators: duck => ({
		setActiveModule: module => ({
			type: duck.types.SET_ACTIVE_MODULE,
			module,
		}),
		fetchTraceListing: (
			docType,
			rootModules = [],
			locationState = {},
			skipGlobalLoader = false
		) => ({
			type: duck.types.FETCH_PRODUCT_TRACE_LISTING,
			rootModule: rootModules[0],
			submodule: docType,
			locationState,
			skipGlobalLoader,
		}),
		traceDocumentLoading: status => ({
			type: duck.types.TRACE_DOCUMENT_LOADING_STATUS,
			status,
		}),
		setActiveProductID: activeProductID => ({
			type: duck.types.SET_ACTIVE_PRODUCT_ID,
			activeProductID,
		}),
		setTracePaginationEntries: (
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor
		) => ({
			type: duck.types.SET_TRACE_PAGINATION_ENTRIES,
			activeIndex,
			limit,
			total,
			nextCursor,
			currentCursor,
		}),
		setActiveProductListing: listing => ({
			type: duck.types.SET_ACTIVE_PRODUCT_LISTING,
			listing,
		}),
		fetchProductOrgTrace: traceID => ({
			type: duck.types.FETCH_PRODUCT_ORG_TRACE,
			traceID,
		}),
		fetchProductTrace: (traceID, orgID, isTraceGroup = false) => ({
			type: duck.types.FETCH_PRODUCT_TRACE,
			traceID,
			orgID,
			isTraceGroup,
		}),
		fetchGeoData: (geoID, orgID) => ({
			type: duck.types.FETCH_GEO_DATA,
			geoID,
			orgID,
		}),
		fetchProductTreeTraceSuccess: traceDocuments => ({
			type: duck.types.FETCH_PRODUCT_TREE_TRACE_SUCCESS,
			traceDocuments,
		}),
		fetchProductGraphTraceSuccess: traceDocuments => ({
			type: duck.types.FETCH_PRODUCT_GRAPH_TRACE_SUCCESS,
			traceDocuments,
		}),
		fetchProductMapTraceSuccess: traceDocuments => ({
			type: duck.types.FETCH_PRODUCT_MAP_TRACE_SUCCESS,
			traceDocuments,
		}),
		setGeoData: data => ({
			type: duck.types.SET_GEO_DATA,
			data,
		}),
		setActiveSorts: sortMap => ({
			type: duck.types.SET_ACTIVE_SORTS,
			sortMap,
		}),
		setActiveFilters: filters => ({
			type: duck.types.SET_ACTIVE_FILTERS,
			filters,
		}),
		flushTraceListing: () => ({
			type: duck.types.FLUSH_TRACE_LISTING,
		}),
		flushTraceDocument: () => ({
			type: duck.types.FLUSH_TRACE_DOCUMENT,
		}),
		fetchBadNodePaths: badNodePaths => ({
			type: duck.types.FETCH_BAD_NODE_PATHS,
			badNodePaths,
		}),
		checkBlockchainDisabled: () => ({
			type: duck.types.CHECK_BLOCKCHAIN_DISABLED,
		}),
		setBlockChainStatus: status => ({
			type: duck.types.SET_BLOCKCHAIN_STATUS,
			status,
		}),
		getHederaMessages: traceIDs => ({
			type: duck.types.GET_HEDERA_MESSAGES,
			traceIDs,
		}),
		setHederaMessages: data => ({
			type: duck.types.SET_HEDERA_MESSAGES,
			data,
		}),
		getDeforestationEvents: orgID => ({
			type: duck.types.GET_DEFORESTATION_EVENTS,
			orgID,
		}),
		setDeforestationEvents: data => ({
			type: duck.types.SET_DEFORESTATION_EVENTS,
			data,
		}),
	}),
})

import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import { AuthDuc } from 'core-app/modules/Auth/duc'

import { Breadcrumb } from 'ui-lib/components/Breadcrumb'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { MainRouteDuc } from 'core-app/routes/duc'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import BackArrowIcon from 'ui-lib/icons/arrow-back.svg'
import { Title } from 'core-app/shared/components/Title'
import { Spacer } from 'ui-lib/utils/Spacer'
import { isEmptyObject } from 'core-app/utils/helpers'
import { addLast, merge } from 'timm'
import { BoxShadowTable } from 'core-app/shared/components/BoxShadowTable'
import { Label } from 'ui-lib/components/Typography'
import { Button } from 'ui-lib/components/Button'
import * as yup from 'yup'

import { CollapsableSegment } from 'ui-lib/components/CollapsableSegments'
import { useFormik } from 'formik'
import { getMapOutputColumnConfig } from 'core-app/modules/StorageCompany/components/Columns'

import { Box } from 'ui-lib/utils/Box'
import { StorageCompanyDuc } from '../../duc'

const CTAWrapper = styled(Box)(p => ({
	padding: 16,
	minHeight: 64,
	background: p.theme.color.white,
	borderBottomLeftRadius: 4,
	borderBottomRightRadius: 4,
	color: p.theme.color.primary,
	border: `1px solid ${p.theme.color.grey4}`,
}))

const getBreadCrumbsList = t => [
	{
		label: t('breadcrumb.home'),
		name: 'home',
		isActive: true,
	},
	{
		label: t('breadcrumb.storageAssignment'),
		name: 'storageAssignment',
		isActive: true,
	},
	{
		label: t('common.recoredOutput'),
		name: 'mapOutput',
		isActive: false,
	},
]

const validationSchema = yup.object().shape({
	mapOutput: yup
		.array()
		.of(
			yup.object().shape({
				productID: yup
					.string()
					.strict()
					.required(),
				storageUnitID: yup
					.string()
					.strict()
					.required(),
				quantity: yup
					.number()
					.strict()
					.required(),
			})
		)
		.strict()
		.required(),
})

export const CreateMapOutput = () => {
	const { t } = useTranslation()
	const dispatch = useDispatch()

	const [activeDocuments, setActiveDocuments] = useState([])
	const [activeIndex, setActiveIndex] = useState(0)
	const [trigger, setTrigger] = useState(false)

	const allProducts = useSelector(AuthDuc.selectors.getProducts)
	const allTanks = useSelector(AuthDuc.selectors.getTanks)
	const activeTanks = allTanks.map(({ name, id }) => {
		return { id, label: name }
	})
	const activeProducts = allProducts.map(
		({ code: _code, name, id, uom: _uom }) => {
			return { id, label: `${name} (#${_code}) - ${_uom}` }
		}
	)

	const handleBreadCrumbClick = target => {
		if (target === 'home') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.STORAGE_COMPANY
				)
			)
		} else if (target === 'storageAssignment') {
			dispatch(
				MainRouteDuc.creators.switchPage(
					MainRouteDuc.types.STORAGE_COMPANY$LISTING
				)
			)
		}
	}
	const {
		submitForm,
		setFieldValue,
		isSubmitting,
		errors,
		setSubmitting,
	} = useFormik({
		initialValues: {
			mapOutput: [],
		},
		validationSchema,
		enableReinitialize: true,
		onSubmit: () => {
			dispatch(
				StorageCompanyDuc.creators.createMapOutput(
					t('common.successAssign'),
					activeDocuments
				)
			)
			setSubmitting(false)
		},
	})

	const disableCTA = !isEmptyObject(errors) || !trigger

	const handleChange = useCallback(
		(action, meta) => {
			switch (action) {
				case 'add_new_row': {
					const initialActiveBatch = {
						activeIndex,
						date: new Date().toISOString(),
						productID: '',
						storageUnitID: '',
						quantity: '',
					}
					const addRow = addLast(activeDocuments, initialActiveBatch)
					setActiveIndex(Math.max(0, activeIndex + 1))
					setActiveDocuments(addRow)
					setTrigger(false)
					break
				}

				case 'modify_product': {
					const { row = {} } = meta
					const document = activeDocuments.filter(
						currentDoc => currentDoc.activeIndex !== row.activeIndex
					)
					setActiveDocuments(document)

					// eslint-disable-next-line no-unused-expressions
					activeDocuments.length > 1
						? setTrigger(true)
						: setTrigger(false)

					break
				}
				case 'add_Values': {
					const { id, value, type } = meta
					let setValue

					activeDocuments.map((row, index) => {
						if (row.activeIndex === id && type === 'date') {
							activeDocuments[index] = merge(
								activeDocuments[index],
								{
									date: value,
								}
							)
						}
						if (row.activeIndex === id && type === 'productID') {
							activeDocuments[index] = merge(
								activeDocuments[index],
								{
									productID: value,
								}
							)
						}

						if (row.activeIndex === id && type === 'quantity') {
							activeDocuments[index] = merge(
								activeDocuments[index],
								{
									quantity: parseFloat(value, 4),
								}
							)
						}
						if (
							row.activeIndex === id &&
							type === 'storageUnitID'
						) {
							activeDocuments[index] = merge(
								activeDocuments[index],
								{
									storageUnitID: value,
								}
							)
						}

						return setValue
					})
					setFieldValue('mapOutput', activeDocuments)
					setTrigger(true)

					break
				}

				default:
					break
			}
		},
		[
			setActiveDocuments,
			activeDocuments,
			setActiveIndex,
			activeIndex,
			setFieldValue,
		]
	)

	return (
		<Box padding={8} width="100%" height="100%">
			<Box style={{ padding: '0 5' }}>
				<Breadcrumb
					links={getBreadCrumbsList(t)}
					onClick={target => handleBreadCrumbClick(target)}
				/>
			</Box>
			<Box row justifyContent="space-between" alignItems="baseline">
				<Box row style={{ marginTop: '10px' }}>
					<Box>
						<IconWrapper
							size={30}
							onClick={() =>
								dispatch(
									MainRouteDuc.creators.switchPage(
										MainRouteDuc.types
											.STORAGE_COMPANY$LISTING
									)
								)
							}
						>
							<Icon glyph={BackArrowIcon} />
						</IconWrapper>
					</Box>
					<Spacer size={8} horizontal />
					<Title
						small
						style={{ marginTop: 0, marginBottom: 0 }}
						title={t('common.recoredOutput')}
						note={t('storage.createBatch')}
					/>
				</Box>
			</Box>

			<Spacer size={24} />
			<>
				<CollapsableSegment
					expand
					title={t('storage.mapOutgoingUnits')}
					hasError={disableCTA}
					open
					message={
						disableCTA
							? t('validation.collapsableSegmentWarning')
							: t('validation.collapsableSegmentSuccess')
					}
				>
					<Box>
						<Spacer size={24} />
						<BoxShadowTable
							size="large"
							columnConfig={getMapOutputColumnConfig({
								type: 'storage-assignment',
								docType: 'map-output',
								handleChange,
								activeProducts,
								activeTanks,
								t,
							})}
							rowData={activeDocuments}
						/>
						<CTAWrapper
							onClick={() => handleChange('add_new_row', {})}
						>
							<Label color="inherit" bold small link as="a">
								{t('production.addMore')}
							</Label>
						</CTAWrapper>
					</Box>
				</CollapsableSegment>
				<Spacer size={24} />
				<Box style={{ padding: 2 }} alignItems="flex-end">
					<Box width={200}>
						<Button
							disabled={disableCTA}
							isLoading={isSubmitting}
							label={t('common.submit')}
							primary
							rounded
							onClick={() => submitForm()}
						/>
					</Box>
				</Box>
			</>
		</Box>
	)
}

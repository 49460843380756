import React, { useState } from 'react'
import { getIn } from 'timm'
import styled from 'styled-components'
import { Card as GrommetCard, CardHeader, Grommet } from 'grommet'
import leftArrow from '../../icons/left-arrow.svg'
import rightArrow from '../../icons/right-arrow.svg'
// import { SuggestionsInput } from '../Input'
import downArrow from '../../icons/down.svg'
import upArrow from '../../icons/upward-arrow.svg'
import tradeManager from '../../icons/trade-manager.svg'
import { Icon, IconWrapper } from '../../icons/components/Icon'
import theme from '../../utils/base-theme'
import { isIframeForTeamsApp } from '../../utils/helpers'

// eslint-disable-next-line flowtype/no-types-missing-file-annotation
type Props = {
	header?: String,
	subHeader?: String,
	count?: Number,
	showFooter?: Boolean,
	width?: Number,
	dataList?: Array<{
		label?: string,
		hasSubmenu?: Boolean,
		subMenuList?: Array<{
			messages: String,
			chatId: String,
		}>,
	}>,
	onClick: string => void,
	closeMessageBox: string => void,
	handleOnScroll: string => void,
	handleSearch: String => void,
	blocksFwd?: Boolean,
	showSubList?: Boolean,
	bgColor?: String,
	fontColor?: String,
	countBg?: String,
	messageComponent?: Boolean,
	hasSubmenu?: Boolean,
	selectedSubmenu?: Array<{
		label?: String,
		count?: Number,
	}>,
	parentId?: String,
	scrollEvent?: Boolean,
	totalUnreadCount?: Number,
	noCount?: Boolean,
	cardId?: String,
	documentLink?: String,
}

const getContentHeight = showFooter => {
	let height = isIframeForTeamsApp() ? '503px' : '672px'
	if (showFooter) {
		height = isIframeForTeamsApp() ? '455px' : '624px'
	}

	return height
}

const ContentListWrapper = styled.div`
	overflow: auto;
	font-size: 14px;
	max-height: ${props => getContentHeight(props.showFooter)};
	min-height: ${props => getContentHeight(props.showFooter)};
	color: ${theme.color.primary};
	&::-webkit-scrollbar-thumb {
		background-color: ${theme.color.grey16};
		border: 0.5px solid ${theme.color.grey16};
	}
	&::-webkit-scrollbar-thumb:hover {
		background-color: ${theme.color.grey16};
		border: 0.5px solid ${theme.color.grey16};
	}
`

const ContentWrapper = styled.div`
	display: flex;
	height: 46px; // optional
	// border-bottom: 1px solid ${theme.color.white1}; // optional
`

const SearchBox = styled.div`
	width: 417px; // 390px;
	// padding-left: 32px;
`

const MainHeader = styled.div`
	width: ${props => (props.width !== '390' ? '506px' : '340px')};
	display: block;
	max-width: ${props => (props.width !== '390' ? '506px' : '340px')};
`
const plainCard = styled.div`
	max-height: 672px;
	min-height: 672px;
`

const SubHeader = styled.div`
	height: 39px;
	width: 218px;
	font-size: 14px;
	padding-top: 8px;
	padding-left: 32px;
	color: ${props => (props.fontColor ? props.fontColor : theme.color.blue9)};
`

const HeaderText = styled.div`
	font-size: 18px;
	font-weight: 500;
	overflow: hidden;
	padding-left: 32px;
	padding-right: 25px;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: ${props =>
		props.fontColor ? props.fontColor : theme.color.accent2};
	margin-top: ${props => (props.subHeader ? '0px' : '-30px')};
`

const Footer = styled.div`
	height: 50px;
	font-size: 14px;
	padding-top: 15px;
	padding-left: 32px;
	color: ${theme.color.grey4};
	border-top: 1px solid ${theme.color.grey4};
`

const CountReading = styled.div`
	width: 36px;
	height: 36px;
	display: flex;
	font-size: 16px;
	margin-top: -35px;
	margin-right: 18px;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	border: 1px solid ${theme.color.blue8};
	color: ${theme.color.blue8};
`

const NoCountReading = styled.div`
	width: ${props => (props.messageComponent ? '0px' : '36px')};
	height: ${props => (props.messageComponent ? '0px' : '36px')};
	display: flex;
	margin-right: 18px;
`

const DocDownload = styled.div`
	width: 48px;
	height: 48px;
	background-color: #fff;
	border-radius: 10px;
	margin-top: 1px;
	margin-right: 28px;
	cursor: pointer;
`

const IconBox = styled.div`
	width: 36px;
	height: 36px;
	padding: 9px;
	border: 1px solid ${theme.color.primary};
`
const Base = styled.div`
	height: 500px;
	display: flex;
	align-items: center;
	justify-content: center;
`

const ListContainer = styled.div`
	max-width: ${props => (props.noCount ? '367px' : '284px')};
	width: 365px;
	display: block;
	padding-left: 32px;
	padding-right: ${props => (props.noCount ? '37px' : '20px')};
`

const EmptyContainer = styled.div``

const SubLabelWrapper = styled.div`
	font-size: 12px;
	padding-top: 5px;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: ${theme.color.grey15};
`

const LabelText = styled.div`
	font-size: 14px;
	font-weight: ${props => (props.count ? '500' : '400')};
	overflow: hidden;
	padding-right: 10px;
	white-space: nowrap;
	text-overflow: ellipsis;
	color: ${props =>
		props.selected ? theme.color.blue8 : theme.color.black3};
`

const SubMenu = styled.div`
	display: flex;
	padding-top: 8px;
	padding-left: 67px;
	padding-bottom: 10px;
	color; ${theme.color.black3};
	background-color: ${theme.color.grey1};
	border-top: 1px solid ${theme.color.grey4};
`

const SubMenuListWrapper = styled.div``

const SubMenutext = styled.div`
	width: ${props => (props.count > 0 ? '216px' : '299px')};
	padding-top: 9px;
	overflow: hidden;
	padding-right: 20px;
	white-space: nowrap;
	text-overflow: ellipsis;
	font-weight: ${props => (props.count > 0 ? '500' : '400')};
	color: ${props =>
		props.selected ? theme.color.blue8 : theme.color.black3};
`

const ListCountReading = styled.div`
	width: 25px;
	height: 25px;
	display: flex;
	font-size: 13px;
	margin-top: 6px;
	margin-left: 35px;
	margin-right: 23px;
	border-radius: 50%;
	align-items: center;
	justify-content: center;
	color: ${theme.color.white};
	border: 1px solid ${theme.color.red1};
	background-color: ${theme.color.red1};
`

const MessagesModule = ({
	dataList = null,
	header = '',
	subHeader = '',
	// count = null,
	onClick,
	handleOnScroll,
	// handleSearch,
	blocksFwd = false,
	width,
	showFooter = true,
	bgColor,
	fontColor,
	countBg,
	messageComponent = false,
	hasSubmenu = false,
	selectedSubmenu = null,
	showNoData = false,
	scrollEvent = false,
	totalUnreadCount = 0,
	noCount = false,
	cardId,
	documentLink = '',
	closeMessageBox,
	scrollMessage,
	noChatMessage,
}: Props) => {
	const [currentItemId, setCurrentItemId] = useState('')
	const [selectedSubMenuItem, setSelectedSubMenuItem] = useState(null)

	const subMenuList = parentItem => {
		const subMenuDataList = []
		if (
			selectedSubmenu &&
			getIn(selectedSubmenu, ['data']) &&
			getIn(selectedSubmenu, ['data']).length > 0
		) {
			getIn(selectedSubmenu, ['data']).forEach(items => {
				subMenuDataList.push(
					<SubMenu
						onClick={e => {
							e.preventDefault()
							setSelectedSubMenuItem(getIn(items, ['labelId']))
							onClick(items, 'fromChild')
							if (getIn(items, ['count'])) {
								Object.assign(items, { count: 0 })
							}
						}}
					>
						<SubMenutext
							title={getIn(items, ['label'])}
							selected={
								getIn(parentItem, ['id']) === currentItemId &&
								getIn(items, ['labelId']) ===
									selectedSubMenuItem
							}
							count={getIn(items, ['count']) || '0'}
						>
							{getIn(items, ['label']) || ''}
						</SubMenutext>
						{getIn(items, ['count']) &&
						getIn(items, ['count']) > 0 ? (
							<ListCountReading>
								{getIn(items, ['count']) || '0'}
							</ListCountReading>
						) : (
							''
						)}
						<IconWrapper
							size={12}
							style={{
								color: theme.color.grey15,
								paddingTop: '12px',
							}}
						>
							<Icon
								glyph={
									getIn(items, ['id']) === selectedSubMenuItem
										? leftArrow
										: rightArrow
								}
							/>
						</IconWrapper>
					</SubMenu>
				)
			})
		} else if (showNoData) {
			subMenuDataList.push(<SubMenu>{noChatMessage}</SubMenu>)
		}

		return subMenuDataList
	}

	const cardDataList = () => {
		const cardData = []
		if (dataList && dataList.length > 0) {
			dataList.forEach(items => {
				cardData.push(
					<GrommetCard
						style={{
							minHeight: 57,
							width: { width },
							paddingTop: 10,
							borderRadius: 0,
							color: `${theme.color.primary}`,
							borderBottom: `1px solid ${theme.color.grey4}`,
						}}
						background={
							getIn(items, ['id']) === currentItemId
								? theme.color.grey17
								: theme.color.white
						}
						onClick={e => {
							e.preventDefault()
							setCurrentItemId(getIn(items, ['id']))
							if (blocksFwd && !hasSubmenu) {
								onClick(items || '')
							} else if (hasSubmenu) {
								onClick(items, 'fromParent')
							}
						}}
					>
						<ContentWrapper>
							<ListContainer
								title={getIn(items, ['label'])}
								noCount={
									noCount ||
									!(
										getIn(items, ['count']) &&
										getIn(items, ['count']) > 0
									)
								}
							>
								{getIn(items, ['label']) ? (
									<LabelText
										selected={
											getIn(items, ['id']) ===
											currentItemId
										}
										count={
											getIn(items, ['count']) &&
											getIn(items, ['count']) > 0
										}
									>
										{getIn(items, ['label']) || ''}
									</LabelText>
								) : (
									''
								)}
								{getIn(items, ['subLabel']) ? (
									<SubLabelWrapper
										title={
											hasSubmenu
												? getIn(items, ['subLabel'])
												: getIn(items, [
														'subLabel',
												  ]).toUpperCase()
										}
									>
										{hasSubmenu
											? getIn(items, ['subLabel'])
											: getIn(items, [
													'subLabel',
											  ]).toUpperCase() || ''}
									</SubLabelWrapper>
								) : (
									''
								)}
							</ListContainer>
							{!noCount &&
							getIn(items, ['count']) &&
							getIn(items, ['count']) > 0 ? (
								<ListCountReading>
									{getIn(items, ['count'])
										? getIn(items, ['count'])
										: '0'}
								</ListCountReading>
							) : (
								<EmptyContainer />
							)}
							{hasSubmenu ? (
								<IconWrapper
									size={12}
									style={{
										color:
											getIn(items, ['id']) ===
											currentItemId
												? theme.color.blue8
												: theme.color.black3,
										paddingTop:
											getIn(items, ['id']) ===
											currentItemId
												? '6px'
												: '12px',
										height:
											getIn(items, ['id']) ===
											currentItemId
												? '24px'
												: '',
										width:
											getIn(items, ['id']) ===
											currentItemId
												? '24px'
												: '',
										marginLeft:
											getIn(items, ['id']) ===
											currentItemId
												? '-7px'
												: '',
									}}
								>
									<Icon
										glyph={
											getIn(items, ['id']) ===
											currentItemId
												? upArrow
												: downArrow
										}
									/>
								</IconWrapper>
							) : (
								<IconWrapper
									size={12}
									style={{
										color:
											getIn(items, ['id']) ===
											currentItemId
												? theme.color.blue8
												: theme.color.black3,
										paddingTop: '12px',
									}}
								>
									<Icon
										glyph={
											getIn(items, ['id']) ===
											currentItemId
												? leftArrow
												: rightArrow
										}
									/>
								</IconWrapper>
							)}
						</ContentWrapper>
						{selectedSubmenu &&
						getIn(selectedSubmenu, ['parentId']) ===
							getIn(items, ['id']) ? (
							<SubMenuListWrapper>
								{subMenuList(items)}
							</SubMenuListWrapper>
						) : (
							''
						)}
					</GrommetCard>
				)
			})
		} else {
			cardData.push(<Base>No Chat Available</Base>)
		}

		return cardData
	}

	return (
		<GrommetCard
			style={{
				width: { width },
				borderRadius: 10,
				border: `1px solid ${theme.color.grey4}`,
			}}
			background={theme.color.white}
		>
			<CardHeader
				{...(!messageComponent
					? { onClick: () => closeMessageBox() }
					: {})}
				style={{
					height: 96,
					backgroundColor: bgColor || theme.color.blue10,
					borderBottom: `1px solid ${theme.color.grey4}`,
				}}
			>
				<MainHeader width={width}>
					<HeaderText
						fontColor={fontColor}
						title={header}
						subHeader={subHeader}
					>
						{header}
					</HeaderText>
					{subHeader ? (
						<SubHeader fontColor={fontColor} title={subHeader}>
							{subHeader}
						</SubHeader>
					) : (
						''
					)}
				</MainHeader>
				{messageComponent ? (
					<DocDownload
						onClick={() => {
							window.open(documentLink || '')
						}}
					>
						<Icon
							style={{
								color: theme.color.green1,
								height: '49px',
								width: '45px',
								paddingLeft: '4px',
							}}
							glyph={tradeManager}
						/>
					</DocDownload>
				) : (
					''
				)}
				{!noCount && !messageComponent && totalUnreadCount > 0 ? (
					<CountReading countBg={countBg}>
						{totalUnreadCount}
					</CountReading>
				) : (
					<NoCountReading messageComponent={messageComponent} />
				)}
			</CardHeader>
			{messageComponent ? (
				<plainCard>{dataList}</plainCard>
			) : (
				<ContentListWrapper
					id={`${cardId}-contentListwrapper`}
					showFooter={showFooter}
					onScroll={event => {
						if (scrollEvent) {
							event.preventDefault()
							event.stopPropagation()
							handleOnScroll(
								document.getElementById(
									`${cardId}-contentListwrapper`
								).scrollHeight,
								document.getElementById(
									`${cardId}-contentListwrapper`
								).scrollTop,
								event
							)
						}
					}}
				>
					{cardDataList()}
				</ContentListWrapper>
			)}
			{showFooter ? <Footer>{scrollMessage}</Footer> : ''}
		</GrommetCard>
	)
}

export { MessagesModule }

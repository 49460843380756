import { StorageCompanyDuc } from 'core-app/modules/StorageCompany/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { getIn } from 'timm'
import { AuthDuc } from 'core-app/modules/Auth/duc'
import { ProductsDuc } from 'core-app/modules/Products/duc'

import saga from 'core-app/modules/StorageCompany/StorageCompanySaga'

export default async (dispatch, getState, { action, extra }) => {
	const { type, customMeta = {} } = action
	const currentLocationFromAction = getIn(action, [
		'meta',
		'location',
		'current',
	])
	/** if someone specifically wants to skip thunk fetch again, this check helps */
	const { skipRouteThunk } = customMeta
	if (skipRouteThunk) return

	// check if nothing changed in the route.

	const state = getState()

	const locationState = currentLocationFromAction || state.location
	// eslint-disable-next-line no-unused-vars
	const { payload } = locationState

	// Inject the saga here. If it wasn't already
	extra.getSagaInjectors().injectSaga('storageCompany', { saga })
	dispatch(AuthDuc.creators.fetchActor())
	const isMainDashboard = MainRouteDuc.types.STORAGE_COMPANY === type
	const isSubDashboard = MainRouteDuc.types.STORAGE_COMPANY$LISTING === type
	const isDocumentCreation =
		MainRouteDuc.types.STORAGE_COMPANY$SUBROOT === type
	const isSubmoduleDocumentListing =
		MainRouteDuc.types.STORAGE_COMPANY$SUBMODULELISTING === type
	let activeModule = ''

	if (isMainDashboard) {
		// load the dashboard of the core module
		activeModule = 'dashboard'
		dispatch(StorageCompanyDuc.creators.fetchInsights())
	}
	if (isSubDashboard) {
		activeModule = 'documentListing'
		dispatch(StorageCompanyDuc.creators.fetchStorageListing())
	}
	if (isDocumentCreation) {
		activeModule = 'document'
		dispatch(AuthDuc.creators.fetchAllProducts())
		if (payload.rootModule === 'assign-tank') {
			const { action: _action } = payload
			if (_action === 'create') {
				dispatch(ProductsDuc.creators.fetchOutgoingStorageUnits())
				dispatch(StorageCompanyDuc.creators.assignTankCreate())
			} else if (_action === 'view') {
				dispatch(StorageCompanyDuc.creators.assignTankView())
			}
		} else if (payload.rootModule === 'map-output') {
			const { action: _action } = payload
			if (_action === 'view') {
				dispatch(StorageCompanyDuc.creators.mapOutgoingView())
			} else if (_action === 'create') {
				dispatch(StorageCompanyDuc.creators.mapOutgoingCreate())
			}
		}
	}
	if (isSubmoduleDocumentListing) {
		activeModule = 'storageListing'
		dispatch(
			StorageCompanyDuc.creators.fetchDocumentListing(
				payload.submodule,
				[payload.rootModule],
				locationState
			)
		)
	}
	dispatch(StorageCompanyDuc.creators.setActiveModule(activeModule))
}

import React, { useState } from 'react'
import { ORG_ALIASES_KEYS, docKeys } from 'core-app/modules/Admin/config'
import { getIn } from 'timm'
import { Box } from 'ui-lib/utils/Box'
import { Card } from 'ui-lib/components/Card'
import { FileLists } from 'ui-lib/components/FileUpload'
import { ButtonWithNoBorder, ButtonIconWrapper } from 'ui-lib/components/Button'
import { Icon } from 'ui-lib/icons/components/Icon'
import EditIcon from 'ui-lib/icons/edit.svg'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'
import { Spacer } from 'ui-lib/utils/Spacer'
import { Modal } from 'ui-lib/components/Modal'
import { ErrorBlock } from 'ui-lib/components/ErrorBlocks'
import { AdminDuc } from 'core-app/modules/Admin/duc'
import { WebTourDuc } from 'core-app/modules/WebTour/duc'
import { useDispatch, useSelector } from 'react-redux'
import { AppDuc } from 'core-app/modules/App/duc'
import { KeyValueSegment } from 'core-app/modules/Admin/components/KeyValueSegment'
import moment from 'moment'
import EditDocument from './EditDocument'

const DocumentBlock = ({ isMobile, t, docDetails }) => {
	const [showDeleteModal, setShowDeleteModal] = useState(false)
	const [showModal, setShowModal] = useState(false)
	const dispatch = useDispatch()

	const getValue = key => {
		if (key === 'type') {
			return t(`viewCompanyInfo.${docDetails[key]}`)
		}
		if (key === 'issueDate') {
			return docDetails[key]
				? moment(docDetails[key]).format('YYYY-MM-DD')
				: ''
		}
		if (key === 'updatedBy') {
			return getIn(docDetails, ['meta', 'updatedBy'])
		}
		if (key === 'createdAt') {
			return docDetails[key]
				? moment(docDetails[key]).format('YYYY-MM-DD hh:mm A')
				: ''
		}

		return docDetails[key]
	}

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showDeleteModal}
				heading={t('viewCompanyInfo.deleteDocument')}
				body={<div>{t('viewCompanyInfo.deleteDocumentNote')}</div>}
				closelabel={t('common.cancel')}
				confirmlabel={t('common.yes')}
				onClose={() => setShowDeleteModal(false)}
				onConfirm={() => {
					setShowDeleteModal(false)
					dispatch(
						AdminDuc.creators.deleteSingleCertificate(
							docDetails.id,
							t('toast.deleteSuccess')
						)
					)
				}}
			/>
			<Modal
				size="large"
				show={showModal}
				heading={t('viewCompanyInfo.updateDocument')}
				body={
					<EditDocument
						docDetails={docDetails}
						handleShowModal={value => {
							setShowModal(value)
						}}
						edit
					/>
				}
				hideButtons
				onConfirm={() => {
					setShowModal(false)
				}}
			/>
			<Box row={!isMobile} padding="20px">
				<Card
					style={{
						padding: '20px',
						position: 'relative',
						overflow: 'visible',
						boxShadow: ' 0px 6px 16px #00000014',
					}}
					textAlign="left"
				>
					<Box row justifyContent="flex-end">
						<ButtonWithNoBorder
							label={t('common.delete')}
							action
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={DeleteIcon} />
								</ButtonIconWrapper>
							}
							onClick={() => {
								dispatch(
									WebTourDuc.creators.setActiveTourModule('')
								)
								setShowDeleteModal(true)
							}}
						/>
						<Spacer size={16} />

						<ButtonWithNoBorder
							label={t('common.edit')}
							action
							rounded
							customIcon={
								<ButtonIconWrapper lightBG>
									<Icon glyph={EditIcon} />
								</ButtonIconWrapper>
							}
							onClick={() => {
								dispatch(
									WebTourDuc.creators.setActiveTourModule('')
								)
								setShowModal(true)
							}}
						/>
					</Box>
					<Box row style={{ width: '100%' }}>
						<Box style={{ width: '50%' }}>
							{docKeys.map(key => {
								return (
									<KeyValueSegment
										key={key}
										labelWidth="50%"
										label={t(ORG_ALIASES_KEYS[key])}
										value={getValue(key)}
									/>
								)
							})}
						</Box>
						<Box style={{ width: '50%' }}>
							<KeyValueSegment
								key="issuingBody"
								labelWidth="50%"
								label={t(ORG_ALIASES_KEYS.issuingBody)}
								value={
									getIn(docDetails, [
										'meta',
										'certificate',
										'issuingBody',
									]) || ''
								}
							/>
							<FileLists
								documents={docDetails.files || []}
								isReadOnly
							/>
						</Box>
					</Box>
				</Card>
			</Box>
		</>
	)
}
const OtherDocuments = ({ otherDocuments, t }) => {
	const { isMobile } = useSelector(AppDuc.selectors.detection)
	const [showModal, setShowModal] = useState(false)
	const docDetails = {
		number: '',
		issueDate: '',
		files: [],
		type: '',
		meta: {
			certificate: {
				issuingBody: '',
				otherIssuingBody: false,
			},
		},
	}
	const dispatch = useDispatch()

	return (
		<>
			<Modal
				forceCloseviaButton
				size="large"
				show={showModal}
				heading={t('viewCompanyInfo.addNewDocument')}
				body={
					<EditDocument
						docDetails={docDetails}
						handleShowModal={value => {
							setShowModal(value)
						}}
					/>
				}
				hideButtons
				onConfirm={() => {
					setShowModal(false)
				}}
			/>
			<Box row justifyContent="flex-end" padding="20px 20px 0px 0px">
				<ButtonWithNoBorder
					label={t('onboarding.addNew')}
					action
					rounded
					customIcon={
						<ButtonIconWrapper lightBG>
							<Icon glyph={EditIcon} />
						</ButtonIconWrapper>
					}
					onClick={() => {
						dispatch(WebTourDuc.creators.setActiveTourModule(''))
						setShowModal(true)
					}}
				/>
			</Box>
			{otherDocuments.length ? (
				(otherDocuments || []).map(otherDocument => (
					<DocumentBlock
						isMobile={isMobile}
						key={otherDocument.id}
						t={t}
						docDetails={otherDocument}
						isOnlyOneCertificateAvailable={
							otherDocuments.length === 1
						}
					/>
				))
			) : (
				<Box style={{ minHeight: 300 }}>
					<ErrorBlock
						hideButton
						status="empty"
						message={t('common.errorBlockMessage')}
					/>
				</Box>
			)}
		</>
	)
}

export default OtherDocuments

import LogHelper from 'core-app/utils/logger'
import { PartnerDuc } from 'core-app/modules/Partner/duc'
import { AppDuc } from 'core-app/modules/App/duc'
import { MainRouteDuc } from 'core-app/routes/duc'
import { CookieDuc } from 'core-app/modules/App/cookieDuc'
import { AUTH_COOKIE_KEYS, currentPlatform } from 'ui-lib/utils/config'
import { Storage } from 'ui-lib/utils/storage'
import { getIAMEndPoint } from 'core-app/config'
import { getIn } from 'timm'
import request from 'core-app/utils/request'
import { transformCertforStore } from 'core-app/utils/helpers'
import {
	CallWithRefreshCheck,
	fetchOrgDetailsCount,
} from 'core-app/modules/Auth/AuthSaga'
import { all, takeLatest, put, call, select } from 'redux-saga/effects'
import { MultipleDocumentListing } from 'core-app/modules/App/AppSaga'
import { Toast } from 'ui-lib/components/Toast'

const logger = LogHelper('client:PartnerSaga')

const PAGINATION_LIMIT = 30

const transformPartnerInfo = response => {
	const {
		id,
		primaryAddress,
		primaryContact,
		status,
		tax,
		categories,
		name,
	} = response

	const category = getIn(response, ['categories', 0, 'id']) || ''
	const parentName =
		getIn(response, ['meta', 'parentOrganization', 0, 'name']) || ''
	const hasParent =
		category === 'palmoil-collectionPoint' && parentName !== ''

	const partnerInfo = {}
	partnerInfo.id = id
	partnerInfo.status = status.state
	partnerInfo.name = hasParent ? `${parentName} - ${name}` : name
	partnerInfo.taxNo = getIn(tax, ['number'])
	partnerInfo.address = getIn(primaryAddress, ['line1'])
	partnerInfo.category = getIn(categories, [0, 'name'])
	partnerInfo.categoryID = getIn(categories, [0, 'id'])
	partnerInfo.city = getIn(primaryAddress, ['city'])
	partnerInfo.state = getIn(primaryAddress, ['state'])
	partnerInfo.country = getIn(primaryAddress, ['country'])
	partnerInfo.pinCode = getIn(primaryAddress, ['postalCode'])
	partnerInfo.phoneNo = getIn(primaryContact, ['mobile'])
	partnerInfo.email = getIn(primaryContact, ['email'])
	if (response?.meta?.properties?.length > 0) {
		partnerInfo.propertiesArray = response.meta.properties
	}

	return partnerInfo
}

const constructURLWithFilter = (baseURL, filterQuery = '') => {
	if (filterQuery.trim() === '') {
		// If filterQuery is empty, return the base URL as is
		return baseURL
	}

	// If filterQuery is not empty, append it to the base URL

	return `${baseURL}${filterQuery}`
}

function* fetchPartnerList(action) {
	try {
		// Extract values from action
		const {
			docType,
			locationState = {},
			skipGlobalLoader,
			filterQuery = '',
		} = action

		// Get state from the dealer collection point
		const selectedCPID = yield select(CookieDuc.selectors.getSelectedCPID)

		if (!skipGlobalLoader)
			yield put(AppDuc.creators.showGlobalLoader('fetch-partner'))

		// Extract organization ID from the client ID
		const [, orgID] = Storage.get({
			name: AUTH_COOKIE_KEYS.CLIENT_ID,
		}).split('@')

		// Construct and call the organization details URL
		const orgDetailsUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const orgDetailsResponse = yield call(request, orgDetailsUrl)
		const { categories = [] } = orgDetailsResponse.data || {}

		const category = getIn(categories, [0, 'id']) || ''
		const isMill = category === 'palmoil-mill' || category === 'rice-mill'

		// If the organization is a mill, fetch and store the RSPO certifications
		if (
			isMill &&
			currentPlatform() !== 'rice' &&
			(docType === 'supplybaseMembers' || docType === 'all')
		) {
			const rspoCertificationsURL = `${getIAMEndPoint()}clients/supplybasemembers`

			const rspoResponse = yield CallWithRefreshCheck(
				rspoCertificationsURL
			)
			const rspoCertificateList = getIn(rspoResponse, ['data']) || {}

			yield put(
				PartnerDuc.creators.setSupplyBaseValues(rspoCertificateList)
			)
		}

		if (docType === 'globalPartnersList') {
			// excel upload
			const globalPartnersListURL = constructURLWithFilter(
				`${getIAMEndPoint()}clients/organizations/${orgID}/partnersList/global?receiverAccepted=false`,
				filterQuery
			)
			const globalPartnersListResponse = yield CallWithRefreshCheck(
				globalPartnersListURL
			)
			const globalPartnersList =
				getIn(globalPartnersListResponse, ['data', 'list']) || []

			yield put(
				PartnerDuc.creators.setGlobalPartnersList(globalPartnersList)
			)
		}

		// Fetch and store the referral list
		if (docType === 'referralList' || docType === 'all') {
			const referralListURL = constructURLWithFilter(
				`${getIAMEndPoint()}clients/organizations/-/referralsList`,
				filterQuery
			)
			const referralListResponse = yield CallWithRefreshCheck(
				referralListURL
			)
			const referralList =
				getIn(referralListResponse, ['data', 'list']) || []

			yield put(PartnerDuc.creators.setReferralList(referralList))
		}

		const INITIAL_TYPES = []
		if (docType === 'all') {
			INITIAL_TYPES.push('partners', 'partnerships')
		} else {
			INITIAL_TYPES.push(docType)
		}

		// Create list of pipeline types, each with an associated URL
		const pipelineTypesWithUrls = INITIAL_TYPES.map(_type => {
			let partnershipUrl = ''

			if (_type === 'partners') {
				partnershipUrl = selectedCPID
					? constructURLWithFilter(
							`${getIAMEndPoint()}clients/partnerships/childorg/${selectedCPID}?status=neq(pending)`,
							filterQuery
					  )
					: constructURLWithFilter(
							`${getIAMEndPoint()}clients/organizations/-/partnersList?receiverAccepted=true`,
							filterQuery
					  )
			} else {
				partnershipUrl = selectedCPID
					? constructURLWithFilter(
							`${getIAMEndPoint()}clients/partnerships/childorg/${selectedCPID}?status=eq(pending)`,
							filterQuery
					  )
					: constructURLWithFilter(
							`${getIAMEndPoint()}clients/organizations/-/partnersList?receiverAccepted=false&partnershipstatus=pending`,
							filterQuery
					  )
			}

			return {
				type: _type,
				url: partnershipUrl,
			}
		})

		const {
			responses = {},
			filterQueriesFromBE = {},
		} = yield MultipleDocumentListing(
			pipelineTypesWithUrls,
			locationState,
			INITIAL_TYPES,
			PAGINATION_LIMIT
		)

		yield put(
			PartnerDuc.creators.setActiveFilterEntries(
				docType,
				filterQueriesFromBE
			)
		)

		yield put(PartnerDuc.creators.setPartnerList(docType, responses))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('fetch-partner'))
	}
}

function* initiatePartnerDetails(action) {
	try {
		const { partnerReqID } = action

		const [, currentOrgID] = Storage.get({
			name: AUTH_COOKIE_KEYS.CLIENT_ID,
		}).split('@')

		yield put(AppDuc.creators.showGlobalLoader('initiate-partner-confirm'))
		// make an API call to get the initiatorID
		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/partnerships/${partnerReqID}`
		const options = {
			method: 'GET',
		}
		const { data } = yield call(request, requestUrl, options)

		const initiatorID = getIn(data, ['initiatorID'])
		const receiverID = getIn(data, ['receiverID'])
		const status = getIn(data, ['status'])

		const orgID = currentOrgID === initiatorID ? receiverID : initiatorID

		yield put(PartnerDuc.creators.setPartnerRequestStatus(status))
		yield put(PartnerDuc.creators.fetchPartnerDetails(orgID))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('initiate-partner-confirm'))
	}
}

function* fetchPartnerDetails(action) {
	try {
		const { orgID } = action

		yield put(AppDuc.creators.showGlobalLoader('initiate-partner-details'))

		const requestUrl = `${getIAMEndPoint()}clients/organizations/${orgID}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const partnerInfo = getIn(origResponse, ['data'])

		const orgType = getIn(partnerInfo, ['categories', 0, 'id'])

		const certificationsURL = `${getIAMEndPoint()}clients/organizations-documents?type=certificate&organizationID=${orgID}`
		const response = yield CallWithRefreshCheck(certificationsURL)
		const certificateList = getIn(response, ['data', 'list']) || []

		const certificates = transformCertforStore(certificateList)

		if (orgType === 'palmoil-smallholder' || orgType === 'rice-farmer') {
			const farmDetailsURL = `${getIAMEndPoint()}clients/farmspaginated?orgID=${orgID}&status=eq(active)`
			const farmDetails = yield CallWithRefreshCheck(farmDetailsURL)
			const farmCertificates = getIn(farmDetails, ['data', 'list']) || []
			const filteredCertificates = farmCertificates.flatMap(obj =>
				obj?.meta?.certificates?.filter(
					certificate => certificate.type === 'certificate'
				)
			)

			yield put(
				PartnerDuc.creators.setFarmCertificates(filteredCertificates)
			)
		}

		const transformedPartnerInfo = yield transformPartnerInfo(partnerInfo)
		yield put(PartnerDuc.creators.setPartnerInfo(transformedPartnerInfo))
		yield put(PartnerDuc.creators.setCertificateDetails(certificates))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	} finally {
		yield put(AppDuc.creators.hideGlobalLoader('initiate-partner-details'))
	}
}

function* sendPartnershipRequest(action) {
	try {
		const { receiverID } = action

		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/partnerships`
		const orgID = {
			receiverID,
		}
		const options = {
			method: 'POST',
			body: JSON.stringify(orgID),
		}

		yield call(request, requestUrl, options)
		yield put(PartnerDuc.creators.sendPartnerReqSuccessToggle(true))
		yield fetchOrgDetailsCount()
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateRequestStatus(action) {
	try {
		const { partnerReqID, status } = action

		const value = {
			receiverAccepted: status === 'true',
		}
		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/partnerships/${partnerReqID}/receiver-status`
		const options = {
			method: 'PUT',
			body: JSON.stringify(value),
		}

		const { data } = yield call(request, requestUrl, options)
		yield put(PartnerDuc.creators.setPartnerRequestStatus(data.status))

		if (status === 'true') {
			yield put(PartnerDuc.creators.partnerAcceptModalToggle(true))
		} else {
			yield put(PartnerDuc.creators.partnerDeclineModalToggle(true))
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateRequestStatusFromDetailsPage(action) {
	try {
		const { partnerReqID, status } = action

		const value = {
			receiverAccepted: status === 'true',
		}
		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/partnerships/${partnerReqID}/receiver-status`
		const options = {
			method: 'PUT',
			body: JSON.stringify(value),
		}

		const { data } = yield call(request, requestUrl, options)

		yield put(PartnerDuc.creators.setPartnerRequestStatus(data.status))

		if (status === 'true') {
			yield put(PartnerDuc.creators.viewPageAcceptModalToggle(true))
		} else {
			yield put(PartnerDuc.creators.viewPageDeclineModalToggle(true))
		}
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* deletePartner(action) {
	try {
		const { partnerReqID } = action

		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/partnerships/${partnerReqID}`
		const options = {
			method: 'DELETE',
		}

		yield call(request, requestUrl, options)

		yield put(PartnerDuc.creators.deletePartnerSuccessToggle(true))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* initiateSmallholderInvite(action) {
	try {
		const { details } = action
		const { name, email, mobile, location, orgType } = details

		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/partners/smallholder-invite`
		const smallholderDetails = {
			name,
			primaryContact: {
				email,
				mobile,
			},
			primaryAddress: {
				line1: location,
			},
			categories: [
				{
					name: orgType,
				},
			],
		}
		const options = {
			method: 'POST',
			body: JSON.stringify(smallholderDetails),
		}

		yield call(request, requestUrl, options)
		yield put(PartnerDuc.creators.invitePartnerReqSuccessToggle(true))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* initiatePartnerInvite(action) {
	try {
		const { details } = action
		const { name, email, mobile, location, orgType } = details

		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/partners/invite`
		const partnerDetails = {
			receiver: {
				name,
				contact: {
					email,
					mobile,
				},
				address: {
					line1: location,
				},
				categories: [
					{
						name: orgType,
					},
				],
			},
		}

		const options = {
			method: 'POST',
			body: JSON.stringify(partnerDetails),
		}

		yield call(request, requestUrl, options)
		yield put(PartnerDuc.creators.invitePartnerReqSuccessToggle(true))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* blackListOrDeactivatePartner(action) {
	try {
		const { partnerReqID, details } = action
		const { type, reason } = details

		let status

		if (type === 'deactivate') {
			status = 'deactivated'
		} else {
			status = 'blacklisted'
		}

		const value = {
			[status]: true,
			reason,
		}
		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/partnerships/${partnerReqID}/DeactivateAndBlackList-status`
		const options = {
			method: 'PUT',
			body: JSON.stringify(value),
		}

		const { data } = yield call(request, requestUrl, options)
		const { status: _status } = data

		Toast({
			type: 'success',
			message: `Partner ${_status}`,
		})

		yield put(MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* updateSupplybaseMembers(action) {
	try {
		const { details, inviteSBMember } = action
		const { organizationID, sbOrgID, email, mobile, name } = details
		const requestUrl = `${getIAMEndPoint()}clients/supplybase/${sbOrgID}/supplybasemember/${organizationID}`
		const payload = {
			...details,
			meta: {
				primaryContact: {
					email,
					mobile,
				},
			},
		}
		const options = {
			method: 'PUT',
			body: JSON.stringify(payload),
		}

		if (inviteSBMember) {
			yield put(
				PartnerDuc.creators.initiatePartnerInvite({
					name,
					email,
					mobile,
					orgType: 'palmoil-supplybase',
				})
			)
		}

		yield call(request, requestUrl, options)
		Toast({
			type: 'success',
		})
		yield put(MainRouteDuc.creators.switchPage(MainRouteDuc.types.PARTNER))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

function* fetchPartnershipTimeline(action) {
	try {
		const { partnerReqID } = action
		const requestUrl = `${getIAMEndPoint()}clients/organizations/-/partnerships/${partnerReqID}`
		const origResponse = yield CallWithRefreshCheck(requestUrl)
		const partnerInfo = getIn(origResponse, ['data', 'statuses'])

		yield put(PartnerDuc.creators.setPartnershipTimeline(partnerInfo))
	} catch (err) {
		const { message } = err

		logger.log(err)

		yield put(
			AppDuc.creators.showToast({
				messageType: 'error',
				message,
			})
		)
	}
}

export default function* PartnerSaga() {
	try {
		yield all([
			takeLatest(
				PartnerDuc.creators.fetchPartnerList().type,
				fetchPartnerList
			),
			takeLatest(
				PartnerDuc.creators.initiatePartnerDetails().type,
				initiatePartnerDetails
			),
			takeLatest(
				PartnerDuc.creators.fetchPartnerDetails().type,
				fetchPartnerDetails
			),
			takeLatest(
				PartnerDuc.creators.sendPartnershipRequest().type,
				sendPartnershipRequest
			),
			takeLatest(
				PartnerDuc.creators.updateRequestStatus().type,
				updateRequestStatus
			),
			takeLatest(
				PartnerDuc.creators.updateRequestStatusFromDetailsPage().type,
				updateRequestStatusFromDetailsPage
			),
			takeLatest(
				PartnerDuc.creators.initiateSmallholderInvite().type,
				initiateSmallholderInvite
			),
			takeLatest(
				PartnerDuc.creators.initiatePartnerInvite().type,
				initiatePartnerInvite
			),
			takeLatest(PartnerDuc.creators.deletePartner().type, deletePartner),
			takeLatest(
				PartnerDuc.creators.blackListOrDeactivatePartner().type,
				blackListOrDeactivatePartner
			),
			takeLatest(
				PartnerDuc.creators.updateSupplybaseMembers().type,
				updateSupplybaseMembers
			),
			takeLatest(
				PartnerDuc.creators.fetchPartnershipTimeline().type,
				fetchPartnershipTimeline
			),
		])
	} catch (err) {
		logger.log(err)
	}
}

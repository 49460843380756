import React from 'react'
import { Box } from 'ui-lib/utils/Box'
import { TableTitle, Label } from 'ui-lib/components/Typography'
import { ButtonWithNoBorder } from 'ui-lib/components/Button'
import { getIn } from 'timm'
import { DatePicker } from 'ui-lib/components/Datepicker'
import { Input } from 'ui-lib/components/Input'
import { SelectSearch } from 'ui-lib/components/Select'
import { Icon, IconWrapper } from 'ui-lib/icons/components/Icon'
import { getDateByFormat } from 'core-app/utils/date'
import DeleteIcon from 'ui-lib/icons/deleteTrash.svg'

import { TABLE_ALIAS } from '../config'

export const getColumnConfig = ({ docType, onChange, t }) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.date)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
			size: '12%',
		},
		{
			property: 'consignmentNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>
						{t(TABLE_ALIAS.consignmentNumber)}
					</TableTitle>
				</Box>
			),
			render: datum => {
				const { number } = datum

				return <Label as="span">{number}</Label>
			},
			primary: true,
			size: '25%',
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const product = getIn(datum, ['products', 0, 'name']) || ''

				return <Label as="span">{product}</Label>
			},
			size: '20%',
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { receiverTraceIDs = [] } = datum
				const quantity = receiverTraceIDs.length
					? receiverTraceIDs
							.reduce((accumulator, object) => {
								return accumulator + object.availableQty
							}, 0)
							.toString()
							.match(/^-?\d+(?:\.\d{0,3})?/)[0]
					: 0
				const uom = getIn(receiverTraceIDs, [0, 'uom']) || ''

				return <Label as="span">{`${quantity} ${uom}`}</Label>
			},
			size: '12%',
		},
		{
			property: 'viewDocument',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.viewDocument)}</TableTitle>
				</Box>
			),
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn')}
						onClick={() =>
							onChange('open_document', {
								row: datum,
								docType,
							})
						}
					/>
				)
			},
		},
		{
			property: 'viewDocument',
			render: datum => {
				const isAssgined =
					getIn(datum, [
						'receiverTraceIDs',
						0,
						'meta',
						'storageUnits',
					]) || []

				return isAssgined.length === 0 ? (
					<ButtonWithNoBorder
						label={t('storage.assignTank')}
						onClick={() =>
							onChange('assign_tank', {
								rowId: datum.id,
								docType,
							})
						}
					/>
				) : (
					<ButtonWithNoBorder
						label={t('storage.detailsTank')}
						onClick={() =>
							onChange('view_tank', {
								traceId: datum.receiverTraceIDs[0].traceID,
							})
						}
					/>
				)
			},
		},
	]
}

export const getOutgoingColumnConfig = ({ docType, onChange, t }) => {
	return [
		{
			property: 'createdAt',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.date)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'batchNumber',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.batchNumber)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { batchReference } = datum

				return <Label as="span">{batchReference}</Label>
			},
		},

		{
			property: 'viewBatch',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.viewBatch)}</TableTitle>
				</Box>
			),
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('common.viewBtn')}
						onClick={() =>
							onChange('open_batch', {
								row: datum,
								docType,
							})
						}
					/>
				)
			},
		},
		{
			property: 'viewDocument',
			render: datum => {
				return (
					<ButtonWithNoBorder
						label={t('storage.generateDO')}
						onClick={() =>
							onChange('generateDO', {
								row: datum,
								docType,
							})
						}
					/>
				)
			},
		},
	]
}

export const getMapOutputColumnConfig = ({
	activeProducts,
	handleChange,
	activeTanks,
	t,
}) => {
	return [
		{
			property: 'date',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.date)}</TableTitle>
				</Box>
			),
			render: datum => {
				const date = getIn(datum, ['date']) || ''

				return (
					<DatePicker
						name={`date-${datum.activeIndex}`}
						value={date}
						onChange={value => {
							handleChange('add_Values', {
								id: datum.activeIndex,
								value,
								type: 'date',
							})
						}}
						placeholder={t('common.selectDate')}
					/>
				)
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['productID']) || ''

				return (
					<SelectSearch
						value={value}
						name={`productID-${datum.activeIndex}`}
						onChange={values => {
							handleChange('add_Values', {
								id: datum.activeIndex,
								value: values.id,
								type: 'productID',
							})
						}}
						labelKey="label"
						valueKey="id"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						options={activeProducts}
						required
					/>
				)
			},
		},
		{
			property: 'storageUnit',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.storageUnit)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['storageUnitID']) || ''

				return (
					<SelectSearch
						value={value}
						name={`storageUnitID-${datum.activeIndex}`}
						onChange={values => {
							handleChange('add_Values', {
								id: datum.activeIndex,
								value: values.id,
								type: 'storageUnitID',
							})
						}}
						labelKey="label"
						valueKey="id"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						options={activeTanks}
						required
					/>
				)
			},
		},

		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const quantity = getIn(datum, ['quantity']) || ''

				return (
					<Box overflow>
						<Input
							name={`quantity-${datum.activeIndex}`}
							value={quantity}
							maxLength={10}
							multiline={false}
							onChange={e => {
								handleChange('add_Values', {
									id: datum.activeIndex,
									value: e.target.value,
									type: 'quantity',
								})
							}}
						/>
					</Box>
				)
			},
		},
		{
			property: 'deleteAction',
			render: datum => {
				return (
					<Box overflow>
						<IconWrapper
							hoverEffect
							width={25}
							height={25}
							onClick={e => {
								e.stopPropagation()
								handleChange('modify_product', {
									row: datum,
									type: 'delete',
								})
							}}
						>
							<Icon glyph={DeleteIcon} />
						</IconWrapper>
					</Box>
				)
			},
		},
	]
}
export const getAssignTankPreview = ({ t, uom }) => {
	return [
		{
			property: 'date',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.date)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'storageUnit',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.storageUnit)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { storageUnit } = datum

				return <Label as="span">{storageUnit}</Label>
			},
			primary: true,
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { quantity } = datum

				return <Label as="span">{`${quantity} ${uom}`}</Label>
			},
		},
	]
}

export const getMapOutputPreview = ({ t, allTanks, allProducts }) => {
	return [
		{
			property: 'date',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.date)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { createdAt } = datum

				return <Label as="span">{getDateByFormat(createdAt)}</Label>
			},
		},
		{
			property: 'product',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.product)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { productID } = datum
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const name = getIn(productName, [0, 'name']) || ''

				return <Label as="span">{name}</Label>
			},
		},
		{
			property: 'storageUnit',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.storageUnit)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { storageUnit } = datum
				const storageName = allTanks.filter(
					storage => storage.id === storageUnit
				)
				const name = getIn(storageName, [0, 'name']) || '---'

				return <Label as="span">{name}</Label>
			},
			primary: true,
		},
		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const { quantity, productID } = datum
				const productName = allProducts.filter(
					product => product.id === productID
				)
				const uom = getIn(productName, [0, 'uom']) || ''

				return <Label as="span">{`${quantity} ${uom}`}</Label>
			},
		},
	]
}

export const getAssignTankColumnConfig = ({
	handleChange,
	activeDocRef,
	allTanks,
	t,
}) => {
	const productID = activeDocRef.product
	const tanksWithThisProduct = allTanks
		.filter(at => at?.products?.includes(productID))
		.map(({ name, id }) => {
			return { id, label: name }
		})

	return [
		{
			property: 'date',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.date)}</TableTitle>
				</Box>
			),
			render: datum => {
				const date = getIn(datum, ['date']) || ''

				return (
					<DatePicker
						name={`date-${datum.activeIndex}`}
						value={date}
						onChange={value => {
							handleChange('add_Values', {
								id: datum.activeIndex,
								value,
								type: 'date',
							})
						}}
						placeholder={t('common.selectDate')}
					/>
				)
			},
		},
		{
			property: 'storageUnit',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.storageUnit)}</TableTitle>
				</Box>
			),
			render: datum => {
				const value = getIn(datum, ['storageUnitID']) || ''

				return (
					<SelectSearch
						value={value}
						name={`storageUnitID-${datum.activeIndex}`}
						onChange={values => {
							handleChange('add_Values', {
								id: datum.activeIndex,
								value: values.id,
								type: 'storageUnitID',
							})
						}}
						labelKey="label"
						valueKey="id"
						defaultLabel={t('common.selectDefaultLabel')}
						returnOnlyValue
						options={tanksWithThisProduct}
						required
					/>
				)
			},
		},

		{
			property: 'quantity',
			header: (
				<Box row alignItems="center" style={{ cursor: 'pointer' }}>
					<TableTitle link>{t(TABLE_ALIAS.quantity)}</TableTitle>
				</Box>
			),
			render: datum => {
				const quantity = getIn(datum, ['quantity']) || ''

				return (
					<Box overflow>
						<Input
							name={`quantity-${datum.activeIndex}`}
							value={quantity}
							maxLength={10}
							onChange={e => {
								handleChange('add_Values', {
									id: datum.activeIndex,
									value: e.target.value,
									type: 'quantity',
								})
							}}
						/>
					</Box>
				)
			},
		},
		{
			property: 'deleteAction',
			render: datum => {
				return (
					<Box overflow style={{ marginBottom: '24px' }}>
						<IconWrapper
							hoverEffect
							width={25}
							height={25}
							onClick={e => {
								e.stopPropagation()
								handleChange('modify_product', {
									row: datum,
									type: 'delete',
								})
							}}
						>
							<Icon glyph={DeleteIcon} />
						</IconWrapper>
					</Box>
				)
			},
		},
	]
}

import React, { useEffect, useState } from 'react'
import { getIn } from 'timm'

import { TextInput } from 'grommet'
import styled from 'styled-components'
import { Box } from '../../utils/Box'
import theme from '../../utils/base-theme'
import { Button } from '../Button'
import { Table } from '../Table'
import { P } from '../Typography'
import { ButtonGroup } from '../ButtonGroup'
import { AreaMapPreview } from '../AreaMapPreview'

const TraceDeforestationEvents = ({
	t,
	treeData = [],
	eventsArray = [],
	orgDetails = [],
	selectedDeforestOrg = [],
	onOrgSelection = () => {},
}) => {
	const [orgData, setOrgDate] = useState(orgDetails)
	const [deforestationEvents, setDeforestationEvents] = useState(eventsArray)
	const [clickedIds, setClickedIds] = useState([])
	const [selectedOrgs, setSelectedOrgs] = useState([])
	const [selectedYears, setSelectedYears] = useState([])
	const [selectedMonth, setSelectedMonth] = useState([])
	const [alertYears, setAlertYears] = useState([])
	const [alertMonths, setAlertMonths] = useState([
		{ label: 'Jan', value: 1 },
		{ label: 'Feb', value: 2 },
		{ label: 'Mar', value: 3 },
		{ label: 'Apr', value: 4 },
		{ label: 'May', value: 5 },
		{ label: 'Jun', value: 6 },
		{ label: 'Jul', value: 7 },
		{ label: 'Aug', value: 8 },
		{ label: 'Sep', value: 9 },
		{ label: 'Oct', value: 10 },
		{ label: 'Nov', value: 11 },
		{ label: 'Dec', value: 12 },
	])

	useEffect(() => {
		setSelectedOrgs(selectedDeforestOrg)
		const initiallySelected = getIn(selectedDeforestOrg, [0, 'value'])
		if (initiallySelected) setClickedIds([initiallySelected])
	}, [selectedDeforestOrg])

	useEffect(() => {
		setDeforestationEvents(eventsArray)
		const current = new Date().getFullYear() - 4
		const years = Array.from({ length: 5 }, (_, index) => current + index)

		const alertYear = years.map(year => ({
			label: year.toString(),
			value: year,
		}))
		setAlertYears(alertYear)
	}, [eventsArray])

	useEffect(() => {
		setOrgDate(orgDetails)
	}, [orgDetails])

	const totalDeforestedArea = deforestationEvents.reduce(
		(accumulator, currentObject) => {
			return accumulator + currentObject.meta.alert_area
		},
		0
	)

	const ScrollView = styled(Box)(p => ({
		flex: p.flex ? 1 : undefined,
		flexShrink: p.flexShrink ? 1 : undefined,
		display: p.block ? 'block' : 'flex',
		overflow: 'scroll',
		margin: '8px',
		padding: '8px',
		border: '1px solid #e4e4e4',
	}))

	const orgTypeFilter = [
		{
			label: t('deforestationAlert.smallholder'),
			value: 'palmoil-smallholder',
		},
		{
			label: t('deforestationAlert.plantation'),
			value: 'palmoil-plantation',
		},
	]

	const eventDescription = {
		first_alert_date: {
			description: t('deforestationAlert.first_alert_date'),
			units: '[date]',
			regions_available: 'All Regions',
		},
		latest_alert_date: {
			description: t('deforestationAlert.latest_alert_date'),
			units: '[date]',
			regions_available: 'All Regions',
		},
		alert_area: {
			description: t('deforestationAlert.alert_area'),
			units: '[ha]',
			regions_available: 'All Regions',
		},
		latest_accum_rate: {
			description: t('deforestationAlert.latest_accum_rate'),
			units: '[ha/day]',
			regions_available: 'All Regions',
		},
		accum_accel: {
			description: t('deforestationAlert.accum_accel'),
			units: '[ha/day/day]',
			regions_available: 'All Regions',
		},
		peat_area: {
			description: t('deforestationAlert.peat_area'),
			units: '[ha]',
			regions_available: 'Southeast Asia',
		},
		mangrove_area: {
			description: t('deforestationAlert.mangrove_area'),
			units: '[ha]',
			regions_available: 'Southeast Asia',
		},
		gfw_protected_area: {
			description: t('deforestationAlert.gfw_protected_area'),
			units: '[ha]',
			regions_available: 'Southeast Asia',
		},
		mature_forest_area: {
			description: t('deforestationAlert.mature_forest_area'),
			units: '[ha]',
			regions_available: 'Southeast Asia',
		},
		forest_carbon: {
			description: t('deforestationAlert.forest_carbon'),
			units: '[metric tons of molecular carbon]',
			regions_available: 'All Regions',
		},
		'dist_to_[crop]': {
			description: t('deforestationAlert.dist_to_[crop]'),
			units: '[meters]',
			regions_available: 'All Regions',
		},
		cluster_no: {
			description: t('deforestationAlert.cluster_no'),
			regions_available: 'All Regions',
		},
		is_false_positive: {
			description: t('deforestationAlert.is_false_positive'),
			regions_available: 'All Regions',
		},
		priority_score: {
			description: t('deforestationAlert.priority_score'),
			regions_available: 'All Regions',
		},
		coconut_high_production_area: {
			description: t('deforestationAlert.coconut_high_production_area'),
			units: '[ha]',
			regions_available: 'Philippines',
		},
		coconut_low_production_area: {
			description: t('deforestationAlert.coconut_low_production_area'),
			units: '[ha]',
			regions_available: 'Philippines',
		},
		coconut_moderate_production_area: {
			description: t(
				'deforestationAlert.coconut_moderate_production_area'
			),
			units: '[ha]',
			regions_available: 'Philippines',
		},
		coconut_very_low_production_area: {
			description: t(
				'deforestationAlert.coconut_very_low_production_area'
			),
			units: '[ha]',
			regions_available: 'Philippines',
		},
		alert_id: {
			description: t('deforestationAlert.alert_id'),
			regions_available: 'All Regions',
		},
		soy_mask_area: {
			description: t('deforestationAlert.soy_mask_area'),
			regions_available: 'South America',
		},
		cocoa_mask_area: {
			description: t('deforestationAlert.cocoa_mask_area'),
			regions_available: 'West Africa',
		},
		deforestation_version: {
			description: t('deforestationAlert.deforestation_version'),
			regions_available: 'All Regions',
		},
		young_regenerating_forest_area: {
			description: t('deforestationAlert.young_regenerating_forest_area'),
			units: '[ha]',
			regions_available: 'Southeast Asia',
		},
		low_density_forest_area: {
			description: t('deforestationAlert.low_density_forest_area'),
			units: '[ha]',
			regions_available: 'Southeast Asia',
		},
		medium_density_forest_area: {
			description: t('deforestationAlert.medium_density_forest_area'),
			units: '[ha]',
			regions_available: 'Southeast Asia',
		},
		high_density_forest_area: {
			description: t('deforestationAlert.high_density_forest_area'),
			units: '[ha]',
			regions_available: 'Southeast Asia',
		},
		sbtn_natural_lands_area: {
			description: t('deforestationAlert.sbtn_natural_lands_area'),
			units: '[ha]',
			regions_available: 'All Regions',
		},
		palm_mask_area: {
			description: t('deforestationAlert.palm_mask_area'),
		},
		dist_to_palm: {
			description: t('deforestationAlert.dist_to_palm'),
		},
	}

	const fieldsToDisplay = [
		'alert_area',
		'first_alert_date',
		'forest_carbon',
		'young_regenerating_forest_area',
		'dist_to_palm',
		'gfw_protected_area',
		'mature_forest_area',
		'is_false_positive',
		'latest_accum_rate',
		'palm_mask_area',
		'peat_area',
		'mangrove_area',
		'latest_alert_date',
		'accum_accel',
		'alert_id',
		'geometry',
		'cluster_no',
		'priority_score',
		'deforestation_version',
		'sbtn_natural_lands_area',
		'low_density_forest_area',
		'medium_density_forest_area',
		'high_density_forest_area',
	]

	// Fallback when description unavailable
	const formatString = inputString => {
		let formattedString =
			inputString.charAt(0).toUpperCase() + inputString.slice(1)

		formattedString = formattedString.replace(/_/g, ' ')

		return formattedString
	}

	const getEventDescription = events => {
		const json = fieldsToDisplay.map(fld => {
			const description =
				getIn(eventDescription, [fld, 'description']) ||
				formatString(fld)

			const regionsAvailable =
				getIn(eventDescription, [fld, 'regions_available']) || '-'

			const units = getIn(eventDescription, [fld, 'units']) || 'N/A'

			const value = getIn(events, [fld]) || '-'

			return {
				description,
				units,
				regionsAvailable,
				value,
			}
		})

		return json
	}

	const RenderEvents = event => {
		const eventList = getIn(event, ['event', 'meta']) || {}

		const eventGeometry = getIn(event, ['event', 'meta', 'geometry']) || {}

		const lastAlertDate =
			getIn(event, ['event', 'latest_alert_date']) || new Date()

		const eventsWithDescription = getEventDescription(eventList)

		return (
			<Box flex justifyContent="space-between">
				<Box padding="10px 0px" alignItems="flex-end">
					<P large bold>
						{t('deforestationAlert.eventDate')}
						{new Date(lastAlertDate).toDateString()}
					</P>
				</Box>
				<Box row>
					<Box padding="10px" width="50%">
						<Table
							columnConfig={[
								{
									header: t('deforestationAlert.description'),
									property: 'description',
									size: '200px',
								},
								{
									header: t('deforestationAlert.value'),
									property: 'value',
									size: '150px',
								},
								{
									header: t('deforestationAlert.units'),
									property: 'units',
									size: '75px',
								},
							]}
							rowData={eventsWithDescription}
						/>
					</Box>
					<Box height="400px" padding="10px" width="50%">
						<AreaMapPreview
							treeData={treeData}
							geoJSON={eventGeometry}
							selectedDeforestOrg={selectedDeforestOrg}
						/>
					</Box>
				</Box>
			</Box>
		)
	}

	const RenderOrgList = ({ buttons = [], onClick = () => {} }) => {
		const [list, setList] = useState(buttons)

		useEffect(() => {
			setList(buttons)
		}, [buttons])

		// Multi select org commented temporarily to support single org selection

		// const onSelect = button => {
		// 	const isExist = selectedOrgs.find(
		// 		item => item.value === button.value
		// 	)
		// 	if (isExist) {
		// 		const newItem = selectedOrgs.filter(
		// 			item => item.value !== button.value
		// 		)
		// 		setSelectedOrgs(newItem)
		// 		setClickedIds(clickedIds.filter(item => item !== button.value))
		// 		onClick(newItem)
		// 	} else {
		// 		const newItem = [...selectedOrgs, button]
		// 		setSelectedOrgs(newItem)
		// 		setClickedIds(oldArray => [...oldArray, button.value])
		// 		onClick(newItem)
		// 	}
		// }

		// Single org select
		const onSelect = button => {
			const newItem = list.filter(item => item.value === button.value)
			setSelectedOrgs(newItem)
			setClickedIds(button.value)
			onClick(newItem)
		}

		const onSearch = searchText => {
			const newData = buttons.filter(
				item =>
					item.label.toLowerCase().indexOf(searchText.toLowerCase()) >
					-1
			)
			setList(newData)
		}

		return (
			<Box>
				<Box margin="0px 0px 10px 0px">
					<TextInput
						style={{
							height: 38,
						}}
						onChange={event => onSearch(event.target.value)}
						placeholder="Search"
					/>
				</Box>
				{list.map(btn => (
					<Box
						label={btn.label}
						key={btn.value}
						name={btn.label}
						onClick={() => onSelect(btn)}
						style={{
							background: clickedIds.includes(btn.value)
								? theme.color.primary
								: 'none',
							color: clickedIds.includes(btn.value)
								? theme.color.white
								: theme.color.primary,
							margin: '2px 6px',
							padding: '4px',
							fontSize: '14px',
							borderRadius: '4px',
							flex: 1,
						}}
					>
						{btn.label}
					</Box>
				))}
			</Box>
		)
	}

	const RenderYearMonth = ({
		buttons = [],
		selected = [],
		onFilterChange = () => {},
		flexDirection = 'row',
	}) => {
		const onSelect = button => {
			const updatedMonths = buttons.map(mon => ({
				...mon,
				selected:
					mon.value === button.value ? !mon.selected : mon.selected,
			}))
			onFilterChange(updatedMonths)
		}

		return (
			<Box
				style={{
					flexDirection,
				}}
			>
				{buttons.map(btn => (
					<Button
						label={btn.label}
						key={btn.value}
						name={btn.label}
						onClick={() => onSelect(btn)}
						extendStyles={{
							background: selected.includes(btn.value)
								? theme.color.primary
								: 'none',
							color: selected.includes(btn.value)
								? theme.color.white
								: theme.color.primary,
							margin:
								flexDirection === 'row' ? '2px 6px' : '6px 2px',
							padding: '2px 8px',
							fontSize: '14px',
							flex: 1,
						}}
					/>
				))}
			</Box>
		)
	}

	const onOrgFilter = (orgs = []) => {
		const orgCategory = orgs.map(d => d.value)
		const newData = orgDetails.filter(item =>
			orgCategory.length > 0 ? orgCategory.includes(item.orgTypeID) : true
		)
		setOrgDate(newData)
	}
	const filterByMonthYear = (months = [], year = []) => {
		const filteredAlerts = eventsArray.filter(alert => {
			const alertDate = new Date(alert.latest_alert_date)
			const alertMonth = alertDate.getMonth() + 1
			const alertYear = alertDate.getFullYear()

			if (
				(months.length === 0 || months.includes(alertMonth)) &&
				(year.length === 0 || year.includes(alertYear))
			) {
				return true
			}

			return false
		})
		setDeforestationEvents(filteredAlerts)
	}

	const onYearMonthChange = (type, val) => {
		const selected = val.filter(d => d.selected).map(d => d.value)
		if (type === 'onYearFilter') {
			setSelectedYears(selected)
			setAlertYears(val)
			filterByMonthYear(selectedMonth, selected)
		}
		if (type === 'onMonthFilter') {
			setSelectedMonth(selected)
			setAlertMonths(val)
			filterByMonthYear(selected, selectedYears)
		}
	}

	return (
		<>
			<Box flex row>
				<Box>
					<ButtonGroup
						onChange={items => onOrgFilter(items)}
						buttons={orgTypeFilter}
					/>
					<ScrollView flex>
						<RenderOrgList
							onClick={items => onOrgSelection(items)}
							flexDirection="column"
							buttons={orgData}
						/>
					</ScrollView>
				</Box>
				<Box flex>
					<Box margin="8px" padding="8px" border="1px solid #e4e4e4">
						<Box row>
							<Box width={280}>
								{t('deforestationAlert.plantationName')}
							</Box>
							<P bold large>
								{' : '}
								{selectedOrgs[0]?.label || ''}
							</P>
						</Box>

						<Box row>
							<Box width={280}>
								{t('deforestationAlert.address')}
							</Box>
							<P bold large>
								{' : '}
								{selectedOrgs[0]?.address || ''}
							</P>
						</Box>
						<Box row>
							<Box width={280}>
								{t('deforestationAlert.totalAreaDeforested')}
							</Box>
							<P bold large>
								{' : '}
								{parseFloat(totalDeforestedArea).toFixed(3) ||
									0}
								{' ha'}
							</P>
						</Box>
						<Box row>
							<Box width={280}>
								{t('deforestationAlert.totalNumberofEvents')}
							</Box>
							<P bold large>
								{' : '}
								{deforestationEvents.length}
							</P>
						</Box>
					</Box>
					<ScrollView flexShrink>
						<Box flex row overflow>
							<RenderYearMonth
								selected={selectedYears}
								buttons={alertYears}
								onFilterChange={items => {
									onYearMonthChange('onYearFilter', items)
								}}
							/>
						</Box>

						<Box flex row overflow margin="10px 0px 0px 0px">
							<RenderYearMonth
								selected={selectedMonth}
								buttons={alertMonths}
								onFilterChange={items =>
									onYearMonthChange('onMonthFilter', items)
								}
							/>
						</Box>
					</ScrollView>
					<Box flexShrink alignItems="center" justifyContent="center">
						<P bold large>
							{t('deforestationAlert.deforestationEvents')}
						</P>
					</Box>

					<ScrollView flex block>
						{deforestationEvents.map(d => (
							<RenderEvents event={d} />
						))}
						{deforestationEvents.length === 0 && (
							<Box flex alignItems="center">
								{t('deforestationAlert.noEventsFound')}
							</Box>
						)}
					</ScrollView>
				</Box>
			</Box>
		</>
	)
}

export { TraceDeforestationEvents }
